import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CheckCircle, CommentOutlined } from '@mui/icons-material';
import { Alert, Avatar, Grid,  } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router';
import CommentDrawer from './CommentDrawer';
import { CreateReport, Likes, Reblog } from './card/PostActions';
import { UserContext } from '../../context/UserContext';
import { QueryClient, useMutation } from '@tanstack/react-query';
import { deleteSinglePost } from '../../controllers/posts/posts';
import { editCommunity } from '../../controllers/community/community';
import { MediaDisplayGallery } from '../media_display.gallery';

const ITEM_HEIGHT = 48;

export const DeletePost = ({id, token, entity_id, statuses}) => {
    const [deleted, setDeleted] = React.useState(false)
    const queryClient = new QueryClient()
    const mutationEdit = useMutation({
        mutationFn: editCommunity,
        onSuccess: (data) => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['editCommunity'] })
        setDeleted(true)
        },
    })
    
    const mutation = useMutation({
        mutationFn: deleteSinglePost,
        onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['deletePost'] })
        mutationEdit.mutate({
          id:entity_id,
          body:{statuses:statuses.filter(item => item !== id)},
          token
        })

        },
    })

    const delete_post = () => {
        mutation.mutate({
            id, 
            token
        })
    }
    
    return ( 
        <>
        {!deleted ?
            <Button onClick={delete_post} variant='contained' fullWidth color='error'>
                Delete
            </Button> 
            :
            <Alert icon={<CheckCircle fontSize="inherit" />} severity="success">
                Deleted.
            </Alert>
        }
        </>
    )
}

export default function SlimPostCard({content, token, entity_id, statuses}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openCommentDrawer, setOpenCommentDrawer] = React.useState(false)
  const [openCommentReplyDrawer, setOpenCommentReplyDrawer] = React.useState(false)
  
  const userDetails = React.useContext(UserContext)

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let author = content?.account
  let navigate = useNavigate()
  
  if(!content){
    return (
      <div>No Content</div>
    )
  }
  
  
  return (
    <div style={{cursor:'pointer', marginBottom:20, padding:20, backgroundColor:'aliceblue', scrollbarWidth:"none"}}>
      <Grid container>
        <Grid item xs={2}>
          <div onClick={() => navigate("/profile/"+author?.id)} style={{cursor:'pointer'}}>
            <Avatar alt="avatar" src={author?.avatar} />
          </div>
        </Grid>
        <Grid item xs={9} >
            <MediaDisplayGallery mediaList={content?.media_attachments ?? []} />

            <Typography>@{author?.username}</Typography>
            <div onClick={() => navigate("/post/"+content?.id)}  dangerouslySetInnerHTML={{__html: content?.content}}></div>

            <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                <Likes favourited={content?.favourited} count={content?.favourites_count} id={content?.id} token={token} />
                
                <CommentDrawer count={content?.replies_count} token={token} id={content?.id} open={openCommentDrawer} setOpen={setOpenCommentDrawer} openCommentReply={openCommentReplyDrawer} setOpenCommentReply={setOpenCommentReplyDrawer} />

                <div>
                    <Button 
                    style={{minWidth:'fit-content'}}
                    onClick={() => {
                        setOpenCommentDrawer(true)
                        setOpenCommentReplyDrawer(true)
                    }} 
                    size="small"
                    ><CommentOutlined /></Button>
                    <Reblog reblogged={content?.reblogged} count={content?.reblogs_count} id={content?.id} token={token} />
                </div>
            </div>
        </Grid>
        <Grid item xs={1} style={{justifyContent:'right', display:'flex'}}>
            <div>
                <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    MenuListProps={{
                    'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                    }}
                >
                    {author?.id === userDetails?.id ?
                        <MenuItem>
                        <DeletePost id={content?.id} token={token} entity_id={entity_id} statuses={statuses} />
                        </MenuItem>
                        :
                        <MenuItem>
                        <CreateReport account_id={author?.id} status_ids={[content?.id]} token={token} />
                        </MenuItem>
                    }
                
                </Menu>
            </div>
        </Grid>
      </Grid>
      </div>
  );
}