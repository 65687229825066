import { useContext, useState } from "react"
import { createMedia } from "../../controllers/entertainment/entertainment"
import { createComment } from "../../controllers/posts/comments"
import { UserContext } from "../../context/UserContext"
import { Button, Card, CardContent, TextField, Typography } from "@mui/material"
import { useNavigate } from "react-router"
import { QueryClient, useMutation } from "@tanstack/react-query"
import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout"

export const EntertainmentCreate = () => {
    const userDetails = useContext(UserContext)

    const [title, setTitle] = useState('')
    const [video_length, setVideoLength] = useState('')
    const [desc, setDesc] = useState('')
    const [iframe, setIframe] = useState('')

    let token = userDetails?.token

    let navigate = useNavigate()

    const queryClient = new QueryClient()

    // create media ( Entertainment ) record on mongoose
    const mutationMongoose = useMutation({
        mutationFn: createMedia,
        onSuccess: (data) => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['createMedia'] })
        navigate("/entertainment/show/"+data.entertainment.iframe)
        },
    })

    const handleCreateMediaRec = (status_id) => {
        mutationMongoose.mutate({
            body: {
                title,
                desc,
                video_length,
                location:{},
                iframe,
                author:[userDetails?.id],
                status:status_id,
                category:'entertainment',
            },
            token
        })
    }

    // create mastodon post
    const mutationCreateMastodonPost = useMutation({
      mutationFn: createComment,
      onSuccess: (data) => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['createComment'] })
        handleCreateMediaRec(data.id)
      },
    })
  
    const createMastodonPost = () => {
        if(!title ||
            !video_length ||
            !desc ||
            !iframe 
        ){
            return(
            alert('All fields must be filled out.'))
        }
        mutationCreateMastodonPost.mutate({
        body:{
            status:iframe,
            visibility:"unlisted",
            media_ids:[]
        },
        token
      })
    }

    let field_key = [
        {
            placeholder:"Media Title *", value:title, setValue:setTitle, example:"Great clip title!"
        },
        {
            placeholder:"Video Length *", value:video_length, setValue:setVideoLength, example:'1:20:00 (h:mm:ss)'
        },
        {
            placeholder:"Description *", value:desc, setValue:setDesc, example:"This movie is about."
        },
        {
            placeholder:"Video IFrame *", value:iframe, setValue:setIframe, example:'<iframe src=" " />'
        },
    ]
    return (
        <MainLoggedInLayout>
            <Card>
                <CardContent>
                    <Typography>Upload Media</Typography>
                    <Typography>Share LGBTQ+ media</Typography>
                    <br />
                    {field_key.map((field, x) =>
                    <div key={x}>
                        <Typography>{field.placeholder}</Typography>
                        <TextField multiline placeholder={field.example} value={field.value} onChange={(e) => field.setValue(e.target.value)} />
                    </div>
                    )}
                </CardContent>
                <Button variant="contained" fullWidth onClick={createMastodonPost}>Upload Content</Button>
            </Card>
        </MainLoggedInLayout>
    )
}