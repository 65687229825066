import { Button, Dialog, DialogContent, TextField } from "@mui/material"
import { QueryClient, useMutation } from "@tanstack/react-query"
import { createComment } from "../../controllers/posts/comments"
import { PostMedia } from "./card/PostMedia"
import { useState } from "react"

export const CreatePost = ({token, open, setOpen, status, setStatus, buttonTitle, visibility}) => {
    const [media_ids, setMediaIds] = useState([])
    const [imageUrls, setImageUrls] = useState([])
    const queryClient = new QueryClient()
    const mutation = useMutation({
        mutationFn: createComment,
        onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['createComment'] })
        setOpen(!open)
        document.getElementById("status").value = ""
        },
    })

    const send_reply = () => {
        mutation.mutate({
        body:{
            status: document.getElementById("status").value,
            visibility,
            media_ids
        },
        token
        })
    }
    
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(!open)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <TextField id="status" fullWidth rows={4} multiline label={buttonTitle} variant="outlined" />
                <PostMedia imageUrls={imageUrls} setImageUrls={setImageUrls} media_ids={media_ids} setMediaIds={setMediaIds} token={token} />
                <Button onClick={send_reply} variant="contained" fullWidth>{buttonTitle}</Button>
            </DialogContent>
        </Dialog>
    )
}