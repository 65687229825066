import { Backdrop, Button, CircularProgress, Container, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react";
import { getToken, verifyAuth } from "../../controllers/accounts/auth";
import { UserDispatchContext } from "../../context/UserContext";

export const Login = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString)
    const code = urlParams.get('code')
    const [open, setOpen] = useState(false);
    const setUserDetails = useContext(UserDispatchContext)
    const [token, setToken] = useState('')
    const [account, setAccount] = useState({})
    const [tempTokens, setTemTokens] = useState({})
    const handleClose = () => {
      setOpen(false);
    };

    const validateCode = async (code) => {
      let stored_token = await getToken(code)
      setTemTokens(stored_token)
    }

    useEffect(() => {
      if(code){
        validateCode(code)
      }
    }, [code])

    useEffect(() => {
      let saveToken = tempTokens?.access_token //?? 'QeqhvrK750W-v7oJB0jmFRH-s5fV0NTeXlseHTy9TCU'
      if(saveToken){
        localStorage.setItem('token', saveToken)
        setToken(saveToken)
        
        const local_account = async () => {
          let result = await verifyAuth(saveToken)
          setAccount(result)
        }

        local_account()
      }
    }, [tempTokens, token])

    useEffect(() => {
      if(token && account?.id){
        localStorage.setItem('user', JSON.stringify(account))
        setUserDetails({token, ...account})
      }
    })
    return (
        <>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Container style={{color:'white', textAlign:'center', padding:10, width:'100%', zIndex:1, display:'block', backgroundColor:'#191b22', position:'sticky', bottom:0}}>
            <Typography style={{margin:10}}>Welcome to MakeGayFriends</Typography>
            <a rel="noreferrer" target="_blank" href={'https://makegayfriends.com/oauth/authorize?client_id='+process.env.REACT_APP_CLIENT_ID+'&scope=read+write&redirect_uri=https://beta.makegayfriends.com&response_type=code'}>
            <Button>Login</Button>
            </a>
            <a rel="noreferrer" target="_blank" href={'https://makegayfriends.com/auth/sign_up'}>
            <Button>Register</Button>
            </a>
        </Container>
        </>
    )
}