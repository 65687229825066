import * as React from 'react';
import List from '@mui/material/List';
import { getAllNotifications } from '../../controllers/notify/notifications';
import { UserContext } from '../../context/UserContext';
import { useQuery } from '@tanstack/react-query';
import { MainLoggedInLayout } from '../../components/layouts/main.loggedIn.layout';
import { Avatar, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { removeHTMLtags } from '../../util/util';

export const NotificationList = () => {
    const userDetails = React.useContext(UserContext)
    let navigate = useNavigate()
    let token = userDetails?.token ?? ''
    const { data, refetch } = useQuery({ queryKey: ['getAllNotifications'], queryFn: () => getAllNotifications(token) })
    let notifications = data ?? []
    React.useEffect(() => {
      refetch()
    })
    return (
    <MainLoggedInLayout>
        {notifications.length > 1 && notifications?.map((notify, i) => (

            <List style={{cursor:'pointer'}} key={i} onClick={() => notify?.status && navigate("/post/"+notify?.status?.id)} sx={{ width: '100%', bgcolor: 'background.paper', overflowY:'auto' }}>
                <div style={{display:'flex', alignItems:'center', margin:10}}>
                    <Avatar onClick={() => navigate("/profile/"+notify.account.id)} alt={notify.account.username} src={notify.account.avatar} style={{marginRight:10}} />

                    <div>
                    {"@"+notify.account.username+" "+(notify.type.includes('admin') ? "Admin Alert : "+notify.type.split(".")[1] : notify.type+"ed you")}
                    <br />
                    {removeHTMLtags(notify?.status?.content)}
                    </div>
                </div>
            {notifications.length < 1 && <Typography style={{textAlign:'center'}}>You have no alerts</Typography>}
            </List>
        
        ))}
    </MainLoggedInLayout>
    )
}