import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import { MediaCard } from './MediaCard';

export const PostList = ({media}) => {
  return (
    <ImageList cols={3} style={{margin:0}}>
      {media.map((item, i) => (
        item.img &&
          <MediaCard key={i} item={item} />
      ))}
    </ImageList>
  );
}