import { useContext, useEffect, useState } from "react"
import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout"
import { SwipeCards } from "../../components/meet/SwipeCards"
import { QueryClient, useMutation } from "@tanstack/react-query"
import { getAccounts } from "../../controllers/accounts/accounts"
import { UserContext } from "../../context/UserContext"
import { Backdrop, Button, Card, CardContent } from "@mui/material"
import { createMatch, getMatches } from "../../controllers/hey_match/match"
import { Loading } from "../../util/util"

export const Meet = () => {
    const [offset, setOffset] = useState(0)
    const [local, setLocal] = useState(true)
    const [errImg, setErrImg] = useState([])
    const [account, setAccount] = useState(null)
    const [filterAccounts, setFilteredAccounts] = useState([])
    const userDetails = useContext(UserContext)
    const [swipeIdx, setSwipeIdx] = useState(0)
    const [loading, setLoading] = useState(true)
    let token = userDetails?.token ?? ''
    const [accounts, setAccounts] = useState([])
    let id = userDetails?.id
    const [matches, setMatches] = useState({})

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 3000);
    })

    const allAccts = async() => {
        let local_accounts = await getAccounts(offset, local, token)
        setAccounts(local_accounts)
        let local_matches = await getMatches({token, id})
        setMatches(local_matches)
        if(!account){
            setAccount(filterAccounts[0])
        }
    }
    
    useEffect(() => {

        const allAccts = async() => {
            let local_accounts = await getAccounts(offset, local, token)
            setAccounts(local_accounts)
            let local_matches = await getMatches({token, id})
            setMatches(local_matches)
            if(!account && local_matches?.heymatch){
                setAccount(local_accounts?.filter(item => !local_matches?.heymatch?.viewed.includes(item.id))[0])
            }
        }

        allAccts()
      }, [id, offset, local, token, account])
      

    useEffect(() => {
        if(matches?.heymatch?.viewed){
            setFilteredAccounts(accounts?.filter(item => !matches?.heymatch?.viewed.includes(item.id)))
        }
        if(!account && accounts.length > 0){
            setAccount(accounts?.filter(item => !matches?.heymatch?.viewed.includes(item.id))[0])

        }
    }, [id, accounts, matches, account])
    
    
    const queryClient = new QueryClient()
    const mutation = useMutation({
        mutationFn: createMatch,
        onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['createMatch'] })
        allAccts()
        },
    })

    const create_match = () => {
        mutation.mutate({
            id:userDetails?.id,
            viewed:[],
            token: userDetails?.token
        })
    }

    if(loading) {
        return (
            <MainLoggedInLayout>
                <Loading />
            </MainLoggedInLayout>
        )
    }

    let profiles = [
        {
            acct:"MakeGayFriends",
            note:"Start making more gay friends now.",
            avatar:'https://storage.googleapis.com/events.makegayfriends.com/event_images/gay_friends.jpg'
        },
        {
            acct:"MakeGayFriends",
            note:"Start making more gay friends now.",
            avatar:'https://storage.googleapis.com/events.makegayfriends.com/event_images/gay_friends2.jpg'
        },
        {
            acct:"MakeGayFriends",
            note:"Start making more gay friends now.",
            avatar:'https://storage.googleapis.com/events.makegayfriends.com/event_images/gay_friends3.jpg'
        }
    ]
    
    return (
        <MainLoggedInLayout>
            {
                accounts?.length > 0 ?

                <>
                {matches?.heymatch?.viewed ?
                <div style={{justifyContent:'center', display:'flex', padding:5}}>
                    <SwipeCards setMatches={setMatches} local={local} setLocal={setLocal} setAccount={setAccount} filterAccounts={filterAccounts} accts_viewed={matches?.heymatch?.viewed} count={accounts?.length} offset={offset} setOffset={setOffset} swipeIdx={swipeIdx} setSwipeIdx={setSwipeIdx} account={account} errImg={errImg} setErrImg={setErrImg} />
                </div>
                :
                <div style={{margin:10, textAlign:'center'}}>
                <SwipeCards accts_viewed={[]} count={accounts?.length} offset={offset} setOffset={setOffset} swipeIdx={swipeIdx} setSwipeIdx={setSwipeIdx} account={profiles[Math.floor(Math.random() * profiles.length)]} errImg={errImg} setErrImg={setErrImg} />
                {!id && <img src="https://storage.googleapis.com/events.makegayfriends.com/gifs/Swipe.gif" style={{maxWidth:500, width:'100%', margin:'auto', padding:10}} alt="swipe friends" />}
                </div>
                }
                
                {id &&
                <Backdrop
                    style={{marginTop:"-16px", }}
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={!matches?.heymatch && !loading}
                >
                    <Button variant="contained" onClick={create_match}>Start Making Friends</Button>
                </Backdrop>}
                </>
                
                :

                <Card>
                    <CardContent>
                    There are no more results.
                    <br />
                    {local ? 
                    <div>
                        <p>
                        Looks like you are searching on Make Gay Friends server only, change your settings to search 
                        all members on all servers on Mastodon connected to ours.
                        </p> 
                        <Button onClick={() => setLocal(false)}>Search All Servers</Button>
                    </div>
                    : 
                    ""}

                    </CardContent>
                </Card>
            }
        </MainLoggedInLayout>
    )
}