export const getComments = async (id, token) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch('https://makegayfriends.com/api/v1/statuses/'+id+'/context', { headers });
    const data = await response.json();
    return data
}

export const createComment = async ({body, token}) => {
    const { ...allValues } = body
    const headers = { 'Authorization': 'Bearer '+token, 'Content-Type': 'application/json' }
    const response = await fetch('https://makegayfriends.com/api/v1/statuses', { method:"POST", headers, body:JSON.stringify(allValues) });
    const data = await response.json();
    return data
}

export const editComment = async ({id, body, token}) => {
    const { ...allValues } = body
    const headers = { 'Authorization': 'Bearer '+token, 'Content-Type': 'application/json' }
    const response = await fetch('https://makegayfriends.com/api/v1/statuses/'+id, { method:"PUT", headers, body:JSON.stringify(allValues) });
    const data = await response.json();
    return data
}