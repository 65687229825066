import { AllInclusive, Checkroom, Flare, Movie, Spa, Sports, SportsBar, TravelExplore } from "@mui/icons-material"

export const CategoryDisplay = ({label, hideLabel}) => {

    const CatView = {alignContent:'center', display:'flex'}

    const displayLocally = (Icon, label) => {
        return (
            <div style={CatView}>
                {Icon}
                {!hideLabel && <span style={{fontWeight:"bold"}}>{label}</span>}
            </div>

        )
    }
    
    return (
        <div style={{paddingBottom:10, marginBottom:5}}>
            {label === "social" && displayLocally(<SportsBar />, "Social")}
            {label === "wellness" && displayLocally(<Spa />, "Wellness")}
            {label === "entertainment" && displayLocally(<Movie />, "Entertainment")}
            {label === "fashion" && displayLocally(<Checkroom />, "Fashion")}
            {label === "spirituality" && displayLocally(<Flare />, "Spirituality")}
            {label === "sport_fitness" && displayLocally(<Sports />, "Sports & Fitness")}
            {label === "travel" && displayLocally(<TravelExplore />, "Travel")}
            {label === "other" && displayLocally(<AllInclusive />, "Other")}
        </div>
    )
}