import { Box, Button, ButtonGroup, Card, CardContent, CardHeader, Grid, Tab, Tabs } from "@mui/material"
import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout"
import { useNavigate } from "react-router"
import { useContext, useEffect, useMemo, useState } from "react";
import { searchAllCommunities } from "../../controllers/community/community";
import { useQuery } from "@tanstack/react-query";
import { UserContext } from "../../context/UserContext";
import { CategoryDisplay } from "../../components/category.display"
import { SearchWidget } from "../../components/search.widget";
import CategoryFilter from "../../components/category.filter";
import { Tune } from "@mui/icons-material";

export const para_label = {fontWeight:'bolder', margin:5, fontSize:12}

export const CommunityCard = ({card_details, navigate}) => {
    return(
        <div style={{zIndex:0, position:"relative", cursor:'pointer'}} onClick={() => navigate("/community/show/"+card_details?._id)}>      
            <div style={{backgroundSize:'cover', backgroundImage:"url('"+card_details.header_img+"')", filter:"alpha(opacity=40)", height:122, width:"100%"}}></div>
            <div style={{backgroundColor:'black', opacity:"0.6", position:"absolute", top:0, zIndex:"100", left:0, height:122, width:"100%"}}>

            </div>
            <div style={{padding:5, position:"absolute", top:0, zIndex:"100", left:0, height:122, width:"100%", color:'white'}}>
                <p style={{...para_label, color:'#00ff40', fontSize:16}}>{card_details.title}</p>
                <p style={para_label}>{card_details.member.length} ️‍joined this group</p>
                <p style={para_label}>{card_details.statuses.length} ️‍🔥 posts</p>
                <CategoryDisplay label={card_details.category} hideLabel={true} />
            </div>
        </div>
    )
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export const ListCommunity = () => {
    const userDetails = useContext(UserContext)
    let token = userDetails?.token
    const [title, setTitle] =  useState('')
    const [category, setCategory] = useState('')
    const [categories, setCategories] = useState([])
    const [page_number, setPageNum] = useState(0)
    const [showCatFilter, setShowCatFilter] = useState(false)
    let limit = 8
    const { data:communities, refetch } = useQuery({ queryKey: ['searchAllCommunites'], queryFn: () => searchAllCommunities({token, title, page_number, category, limit}) })
    useEffect(() => {
        refetch()
    })
    let listCommunities = useMemo(() => communities?.community ?? [],[communities?.community])
    const [allCommunities, setAllCommunities] = useState([])
    const [tempCommunities, setTempCommunities] = useState([])

    useEffect(() => {
        listCommunities.filter(item => setTempCommunities(allCommunities => [...allCommunities, item]))
        let localCats = []
        listCommunities.filter(item => item.category && !localCats.includes(item.category) && localCats.push(item.category))
        setCategories(localCats)
    }, [listCommunities])

    useEffect(() => {
        let jsonObject = tempCommunities.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);

        setAllCommunities(uniqueArray)
    }, [tempCommunities])

    useEffect(() => {
      setPageNum(0)
      if(title || category){
        setAllCommunities([])
        setTempCommunities([])
      }
    }, [title, category])
    
    
    let navigate = useNavigate()
    const [value, setValue] = useState(1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    const handleScroll = (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10;
      if (bottom && listCommunities.length === limit) { 
        setTimeout(() => {
            setPageNum(page_number+1)
        }, 100);
      }
    }
    return (
        <MainLoggedInLayout>
            
            <Card onScroll={handleScroll}  style={{overflowY: 'scroll', maxHeight: '95vh', scrollbarWidth:'none'}}>
                <CardHeader 
                    title={value === 0 ? "My Communities" : "All Communities"} 
                    subheader={<Button variant="contained" onClick={() => navigate("/community/create")}>Create A Community</Button>}
                    />

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="My Communities" {...a11yProps(0)} />
                    <Tab label="All Communites" {...a11yProps(1)} />
                </Tabs>
                </Box>

                <div style={{padding:10}}>
                    <SearchWidget search={title} setSearch={setTitle} />
                    <ButtonGroup style={{marginLeft:10}} variant="contained" aria-label="Basic button group">
                        <Button onClick={() => setShowCatFilter(!showCatFilter)}>Category <Tune /></Button>
                    </ButtonGroup>
                    {showCatFilter && 
                    <div>
                        <CategoryFilter catgories={categories} category={category} setCategory={setCategory} />
                    </div>
                    }
                </div>

                <CustomTabPanel value={value} index={0}>

                    <CardContent style={{padding:0}}>
                        <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {allCommunities.map((_, index) => (
                           ( _.leader.includes(userDetails?.id) || _.member.includes(userDetails?.id) ) &&
                            <Grid item xs={2} sm={4} md={6} key={index}>
                                <CommunityCard card_details={_} navigate={navigate} userDetails={userDetails} />
                            </Grid>
                        ))}
                        </Grid>
                    </CardContent>

                </CustomTabPanel>
                
                <CustomTabPanel value={value} index={1}>

                    <CardContent style={{padding:0}}>
                        <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {allCommunities.map((_, index) => (
                            <Grid item xs={2} sm={4} md={6} key={index}>
                                <CommunityCard card_details={_} navigate={navigate} userDetails={userDetails} />
                            </Grid>
                        ))}
                        </Grid>
                    </CardContent>

                </CustomTabPanel>

            </Card>
        </MainLoggedInLayout>
    )
}