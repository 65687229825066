import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CommentOutlined } from '@mui/icons-material';
import { Avatar, Grid,  } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router';
import CommentDrawer from './CommentDrawer';
import { CreateReport, DeletePost, Likes, Reblog } from './card/PostActions';
import { UserContext } from '../../context/UserContext';
import { PostImages } from '../post_images.widget';
import { removeHTMLtags } from '../../util/util'

const ITEM_HEIGHT = 48;

export default function PostCard({content, token}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openCommentDrawer, setOpenCommentDrawer] = React.useState(false)
  const [openCommentReplyDrawer, setOpenCommentReplyDrawer] = React.useState(false)
  
  const userDetails = React.useContext(UserContext)

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let author = content?.account
  let navigate = useNavigate()
  let post_has_media = content?.media_attachments?.length > 0 ? true : false
  
  if(!content){
    return (
      <div>No Content</div>
    )
  }
  
  
  return (
    <Card sx={{ minWidth: '90%', margin:'auto' }}>
      <Grid container style={{padding:20}}>
        <Grid item xs={6}>
          <div onClick={() => navigate("/profile/"+author?.id)} style={{cursor:'pointer'}}>
            <Avatar alt="Remy Sharp" src={author?.avatar} />
            <Typography>@{author?.username}</Typography>
          </div>
        </Grid>
        <Grid item xs={6} style={{justifyContent:'right', display:'flex'}}>
        <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
          {author?.id === userDetails?.id ?
            <MenuItem>
              <DeletePost id={content?.id} token={token} />
            </MenuItem>
            :
            <MenuItem>
              <CreateReport account_id={author?.id} status_ids={[content?.id]} token={token} />
            </MenuItem>
          }
      
      </Menu>
      </div>
        </Grid>
      </Grid>

      {content?.media_attachments?.length > 0 &&
        <PostImages imgs={content?.media_attachments} /> 
      }
      {content?.content && content?.content.includes('youtu.be') ?
        <iframe
          src={removeHTMLtags(content?.content)}
          style={{width: '100%', height:200}}
          title='Media'
        />
        :
        <div style={{margin:20}} dangerouslySetInnerHTML={{__html: content?.content}}></div>
      }

      <CardContent>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
          <Likes favourited={content?.favourited} count={content?.favourites_count} id={content?.id} token={token} />

          <div>
            <Button 
              style={{minWidth:'fit-content'}}
              onClick={() => {
                setOpenCommentDrawer(true)
                setOpenCommentReplyDrawer(true)
              }} 
              size="small"
            ><CommentOutlined /></Button>
            <Reblog reblogged={content?.reblogged} count={content?.reblogs_count} id={content?.id} token={token} />
          </div>
          
        </div>
        {post_has_media &&
        <div dangerouslySetInnerHTML={{__html: content?.content}}></div>
        }
      </CardContent>
      <CommentDrawer count={content?.replies_count} token={token} id={content?.id} open={openCommentDrawer} setOpen={setOpenCommentDrawer} openCommentReply={openCommentReplyDrawer} setOpenCommentReply={setOpenCommentReplyDrawer} />
    </Card>
  );
}