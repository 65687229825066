import { ImageNotSupported } from "@mui/icons-material"
import { Typography } from "@mui/material"

export const NoPostsIcon = () => {
    return (
    <div style={{width:"100%", justifyContent:'center', display:'flex'}}>
        <div style={{borderStyle:'solid', borderRadius:80, width:80, height:110, paddingLeft:30, paddingRight:15, paddingTop:10 }}>
        <ImageNotSupported style={{fontSize:60}} />
        <Typography style={{fontWeight:'bold'}}>No Posts</Typography>
        </div>
    </div>
    )
}