import { CircularProgress } from "@mui/material"
import { useEffect } from "react"

export const ErrorPage = () => {
    useEffect(() => {
      window.location.href="/"
    }, [])
    
    return (
      <div style={{alignItems:'center', display:'flex', height:'100vh', justifyContent:'center'}}>
        <CircularProgress />
      </div>
    )
}