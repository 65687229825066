import { Button } from "@mui/material"
import { QueryClient, useMutation } from "@tanstack/react-query"
import { editCommunity } from "../../controllers/community/community"
import { useContext } from "react"
import { UserContext } from "../../context/UserContext"

export const JoinCommunityButton = ({community_details}) => {
    let card_details = community_details
    const userDetails = useContext(UserContext)

    const queryClient = new QueryClient()
    const mutationEdit = useMutation({
        mutationFn: editCommunity,
        onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['editCommunity'] })
        },
    })
    

    const sendAddToCommunity = () => {
        mutationEdit.mutate({
            id:card_details?._id, 
            body:card_details.privacy? {member_request:[...card_details.member_request, userDetails?.id]} : {member:[...card_details.member, userDetails?.id]},
            token:userDetails?.token
        })
    }

    return (
        <Button variant="contained" color="success" onClick={sendAddToCommunity}>Join</Button>
    )
}