import { useContext, useEffect, useMemo, useState } from "react"
import { QueryClient, useMutation, useQuery } from "@tanstack/react-query"
import { createInterest, getInterest } from "../../controllers/interest/interest"
import { UserContext, UserDispatchContext } from "../../context/UserContext"
import { getMongooseUserDetails, trackingInterest } from "../../controllers/accounts/mastodon_accounts"
import { CalendarMonth, Checklist, Diversity1, Forum, OpenInNew, PostAdd, ToggleOff, ToggleOn, Tv } from "@mui/icons-material"
import { Avatar, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, Typography } from "@mui/material"
import { useNavigate } from "react-router"
import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout"

const NoMatches = (props) => {
    let matches_details = [
        {
            icon:<Tv />,
            text:'Commenting on media in entertainment.'
        },
        {
            icon:<Forum />,
            text:'Commenting on topics in the forum.'
        },
        {
            icon:<CalendarMonth />,
            text:"Commenting on events you're attending."
        },
        {
            icon:<PostAdd />,
            text:"Commenting on public or private posts."
        },
        {
            icon:<Diversity1 />,
            text:"Commenting on community posts."
        }
    ]
    return (
        <div style={{margin:20}}>
            {props.optIn ?
            <>
                <Typography>
                    You currently have {props.single_interest.users.length < 2 ? 0 : props.single_interest.users.length} friendster matches.
                </Typography>
                <Typography>Your matches are currently coming from content you comment on.</Typography>
                <Typography style={{fontWeight:'bold'}}>
                    Tips on getting more friendster matches:
                </Typography>
            </>
                :
                <Typography>
                    Activity we will use to find friends.
                </Typography>
            }

            <>
                {matches_details.map((info, i) =>
                <div key={i} style={{display:'flex', alignItems:'center'}}>
                    {info.icon}
                    <p style={{fontSize:20, padding:5}}>{info.text}</p>
                </div>
                )}
            </>
        </div>
    )
}

export const InterestsList = () => {
    const userDetails = useContext(UserContext)
    const setUserDetails = useContext(UserDispatchContext)
    let token = userDetails?.token
    let id = userDetails?.id
    let mastodon_id = userDetails?.id

    let navigate = useNavigate()

    const [dataInfoVisible, setDataInfoVisible] = useState(false)
    const [matchInfoVisible, setMatchInfoVisible] = useState(false)
    const [optIn, setOptIn] = useState(false)
    const [match_data, setMatchData] = useState([])

    let bullet_points = {fontWeight:'bold', margin:5, fontSize:15}
    
    const { data:interest, refetch, isLoading } = useQuery({ queryKey: ['getSingleInterest'], queryFn: () => getInterest(token, id) })

    let single_interest = useMemo(() => interest ?? {} ,[interest]) 

    useEffect(() => {
      refetch()
    }, [optIn, refetch])

    useEffect(() => {
      if(single_interest?.auth?._id){
        setOptIn(true)
      } else {
        setOptIn(false)
      }
    }, [single_interest?.auth?._id])
    
    const queryClient = new QueryClient()

    const trackingMutation = useMutation({
        mutationFn: trackingInterest,
        onSuccess: (data) => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['trackInterest'] })
        // if successful udpate userDetails
        let mastodon_user = getMongooseUserDetails(token, mastodon_id)
        let account = {...userDetails, mastodon_user:mastodon_user[0]}
        
        localStorage.setItem('user', JSON.stringify(account))
        setUserDetails({token, ...account})
        },
    })

    const handleUpdateUserInterest = () => {
        trackingMutation.mutate({
        token, 
        mastodon_id, 
        trackingInterest:true
      })
    }

    const mutation = useMutation({
        mutationFn: createInterest,
        onSuccess: (data) => {
            console.log(data)
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['createForum'] })
        setOptIn(true)
        handleUpdateUserInterest()
        },
    })
    
    const handleCreateInterest = () => {
        mutation.mutate({
          mastodon_id,
          token
        })
    }

    let action_style = {display:'flex', alignItems:'center', margin:5};
    
    const injectedJavaScript = `
    const style = document.createElement('style');
    style.innerHTML = \`
        
        a, p.MuiTypography-root {
        display: none;
        }

        .MuiCardContent-root {
        display: none;
        }

        .MuiPaper-root {
        width:50%;
        }
        
    \`;
    document.head.appendChild(style);
    `;

    if(isLoading){
        return(
            <MainLoggedInLayout>
                <LinearProgress />
            </MainLoggedInLayout>
        )
    }
    
    return (
        <MainLoggedInLayout>
            {!optIn ?
                <Card>
                    <CardContent>
                    <Typography variant="h4">
                        Friendster AI.
                    </Typography>
                    <Typography style={bullet_points}>
                        Friendster AI will create a directory of members with similar interests.
                    </Typography>
                    <Typography style={bullet_points}>
                        Your data will be used to find other users who have commented, liked or shared posts that you have engaged with.
                    </Typography>
                    <Typography style={bullet_points}>
                        You both will be able to see a preview of the posts you have in common, your direct comments will not be shared with each other.
                    </Typography>
                    <Typography style={bullet_points}>
                        Find users that have similar interests and see what the similar interests are.
                    </Typography>
                    <Button variant="contained" onClick={handleCreateInterest}>Opt In</Button>
                    </CardContent>
                </Card>
                :
                <>
                <Card>
                    <CardContent>
                        <Typography variant="h4">
                            Friendster AI.
                        </Typography>
                        <Typography>
                        A directory of members in order of most similar interests to least will appear below. 
                        Use the list of match items we provide for conversation starters with your match.
                        </Typography>
                    </CardContent>
                    <div style={{display:'flex', alignItems:'center', margin:10}}>
                        <div style={action_style}>
                            <ToggleOn style={{color:'green'}} onClick={() => console.log('delete interest card')}  /> Comments
                        </div>
                        <div style={action_style}>
                            <ToggleOff onClick={() => setDataInfoVisible(true)} /> Likes
                        </div>
                        <div style={action_style}>
                            <ToggleOff onClick={() => setDataInfoVisible(true)} /> Shares
                        </div>
                        <Dialog
                            open={dataInfoVisible}
                            onClose={() => setDataInfoVisible(false)}
                        >
                            <DialogTitle>Data we are currently using.</DialogTitle>
                            <DialogContent>
                                Comments only.
                                
                                Your account does not have enough activity to activate likes or shares.
                            </DialogContent>
                        </Dialog>
                    </div>
                </Card>

                <Grid container>
                    {single_interest?.users?.map((user, i) =>
                    user?.account.id !== userDetails?.id &&
                    <Grid item key={i} alignItems={'center'} flex={0} width={"33%"}>
                        <div style={{margin:10}}>
                            <Avatar onClick={() => navigate("/profile/"+user?.account.id)} containerStyle={{margin:'auto'}} size={72} rounded source={{ uri: user?.account?.avatar }} />
                            <p style={{textAlign:'center', margin:5}}>@{user.account.acct}</p>
                            <Button onClick={() => {
                                setMatchInfoVisible(true)
                                setMatchData(user)
                                }} style={{flexDirection:'row', alignItems:'center'}}>
                                <Checklist color={'white'} /><p style={{color:'white'}}>{user?.sortOrder} Match Item(s)</p>
                            </Button>
                        </div>
                    </Grid>
                    )}
                </Grid>
                </>
            }

                <Dialog
                    open={matchInfoVisible}
                    onClose={() => setMatchInfoVisible(false)}
                >
                    <DialogTitle>Posts you've both engaged with.</DialogTitle>
                        <div style={{height:300}}>
                        <p>Here are some posts that you and @{match_data?.account?.acct} have in common.</p>
                            <div>
                                {match_data?.comments?.map((comment, i) =>
                                    <div key={i} onClick={() => {
                                        navigate("/"+comment.type+"/show/"+comment.id)
                                        setMatchInfoVisible(false)
                                        }}>
                                        <iframe
                                            src={"https://beta.makegayfriends.com/"+comment.type.toLowerCase()+"/show/"+comment.id }
                                            style={{width:'100%', height:250}}
                                            injectedJavaScript={injectedJavaScript}
                                            title="preview"
                                        />
                                        <div style={{backgroundColor:"rgba(0, 0, 0, .3)", height:250, width:'100%', position:'absolute', justifyContent:'center', display:'flex', alignContent:'center'}}>
                                            <p style={{color:'white', width:'100%', textAlign:'center'}}><OpenInNew size={34} color={'white'} /></p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <DialogActions>
                            <p>Learn more about @{match_data?.account?.acct}</p>

                            <Button onClick={() => {
                                navigate("/profile/"+match_data?.account?.id)
                                setMatchInfoVisible(false)
                                }}>                            
                                    <Avatar containerStyle={{margin:'auto'}} rounded src={ match_data?.account?.avatar } />
                                    {" "}
                                    Learn More
                                </Button>
                        </DialogActions>
                </Dialog>

                {single_interest?.users?.length < 10 &&
                <NoMatches optIn={optIn} single_interest={single_interest} />}
        </MainLoggedInLayout>
    )
}