import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from '@mui/material';
import { RemoveCircle } from '@mui/icons-material';

export default function CategoryFilter({catgories, category, setCategory}) {

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  return (
    <>
      <FormControl style={{margin:10, minWidth:180}}>
        <InputLabel id="demo-simple-select-label">Category</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={category}
          label="Category"
          onChange={handleChange}
        >
            {catgories.map((cat, i) => 
                <MenuItem key={i} value={cat}>{cat}</MenuItem>
            )}
        </Select>
        

      </FormControl>
      {category && 
        <div style={{justifyContent:'center', marginLeft:10}}>
          <Button startIcon={<RemoveCircle />} color='warning' variant='contained' onClick={() => setCategory('')}>{category}</Button>
        </div>
  }
      </>
  );
}