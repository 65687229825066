import { Avatar, AvatarGroup, Button, Card, CardContent, CardHeader, IconButton, Menu, MenuItem, Typography } from "@mui/material"
import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout"
import { useContext, useEffect, useMemo, useState } from "react"
import { UserContext } from "../../context/UserContext"
import { getForum } from "../../controllers/forum/forum"
import { useNavigate, useParams } from "react-router"
import { useQuery } from "@tanstack/react-query"
import { MoreVert } from "@mui/icons-material"
import { CategoryDisplay } from "../../components/category.display"
import SlimCommentDrawer from "../../components/post/SlimCommentDrawer"
import { getAccount } from "../../controllers/accounts/accounts.js"
import { PostImages } from "../../components/post_images.widget"

export const ShowForum = () => {
    const [leaders, setLeaders] = useState([])
    const { id } = useParams()
    const userDetails = useContext(UserContext)
    let token = userDetails?.token
    const { data:forum, refetch } = useQuery({ queryKey: ['getSingleForum'], queryFn: () => getForum(token, id) })

    let single_forum = useMemo(() => forum?.forum ?? {}, [forum?.forum]) 

    useEffect(() => {
      setLeaders([])
      single_forum?.author?.forEach(async (member) => {
          const acct = await getAccount(member, token)
          setLeaders(ids => [...ids, acct])
      })
    }, [single_forum, token])

    const [openCommentReplyDrawer, setOpenCommentReplyDrawer] = useState(false)

    let navigate = useNavigate()
    useEffect(() => {
        refetch()
    })

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    // useEffect(() => {
    //   if(content?.replies_count > 0 && single_forum?.commented === false){
    //     console.log('update forums commented to true')
    //   }
    // }, [openCommentReplyDrawer])
    

    return (
        <MainLoggedInLayout>
            <Card>
                <CardHeader
                    subheader={ 
                    <div style={{float:'right'}}>
                        {single_forum?.author?.includes(userDetails?.id) &&
                        <>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={openMenu ? 'long-menu' : undefined}
                          aria-expanded={openMenu ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={handleClick}
                        >
                          <MoreVert />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            'aria-labelledby': 'long-button',
                          }}
                          anchorEl={anchorEl}
                          open={openMenu}
                          onClose={handleClose}
                        >
                                <MenuItem onClick={handleClose}>
                                    <Button variant="contained" size="small" onClick={() => navigate("/forum/edit/"+single_forum?._id)}>Edit</Button>
                                </MenuItem>
                        </Menu>
                        </>
                        }
                    </div>
                    }
                />   
                <CardContent>
                  {forum?.media_attachments?.length > 0 && <PostImages imgs={forum?.media_attachments ?? []} />}

                  <Typography style={{wordBreak: 'break-word'}}>{single_forum?.title?.split("Original post: ")[0]}</Typography>
                  <a href={single_forum?.title?.split("Original post: ")[1]}>
                    <Button fullWidth variant="clear">View Original {single_forum?.title?.includes('quora') ? "Quora " : ""}Post</Button>
                  </a>
                  <CategoryDisplay label={single_forum?.category} />

                  <div style={{marginBottom:20}}>
                    <p style={{fontWeight:'bold'}}>Author</p>
                    <AvatarGroup style={{justifyContent:'flex-end'}}>
                    {leaders.map((leader, i) =>
                      <Avatar key={i} onClick={() => navigate("/profile/"+leader?.id)} alt="Member" src={leader?.avatar} />
                    )}
                    </AvatarGroup>
                  </div>

                  <SlimCommentDrawer count={forum?.replies_count} token={token} id={forum?.id} openCommentReply={openCommentReplyDrawer} setOpenCommentReply={setOpenCommentReplyDrawer} />

                </CardContent>
            </Card>
            
        </MainLoggedInLayout>
    )
}