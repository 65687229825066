import { useContext, useEffect, useMemo, useState } from "react"
import { UserContext } from "../../context/UserContext"
import { useQuery } from "@tanstack/react-query"
import { getMedia } from "../../controllers/entertainment/entertainment"
import SlimCommentDrawer from "../../components/post/SlimCommentDrawer"
import { Button, Card, CardContent, Container, Drawer, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material"
import { useParams } from "react-router"
import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout"
import { ChevronRight } from "@mui/icons-material"
import { Loading } from "../../util/util"
import useWindowDimensions from "../../util/isMobile"

export const EntertainmentShow = () => {
    const { id } = useParams();
    const userDetails = useContext(UserContext)
    let token = userDetails?.token

    const { data:media, refetch, isLoading } = useQuery({ queryKey: ['getSingleMedia'], queryFn: () => getMedia(token, id) })
    let single_media = useMemo(() => media?.entertainment ?? {},[media?.entertainment]) 

    const [openCommentReplyDrawer, setOpenCommentReplyDrawer] = useState(false);
    const [desc, setDesc] = useState(false);

    const { width } = useWindowDimensions()

    useEffect(() => {
      refetch()
    })
    
    if(isLoading){
        return(
        <MainLoggedInLayout>
            <Loading />
        </MainLoggedInLayout>
        )
    }

    return (
        <MainLoggedInLayout>

        <iframe 
            style={{width:'100%', height:'100vh'}}
            src={single_media?.iframe}
            title="Short Film"
        />

        <Container> 
            <Card>
            <ListItem disablePadding>
                <ListItemButton onClick={() => setDesc(true)}>
                <ListItemText primary={"About "+single_media?.title} />
                <ListItemIcon>
                    <ChevronRight />
                </ListItemIcon>
                </ListItemButton>
            </ListItem>
            </Card>
            <Drawer anchor={'left'} open={desc} onClose={() => setDesc(false)}>
                <CardContent>
                    <Typography>Clip Length : {single_media?.video_length}</Typography>
                    <Typography>{single_media?.title}</Typography>
                    <Typography style={{maxWidth:width/2}}>{single_media?.desc?.replace("more", '').replaceAll("…", '').replaceAll('...','')}</Typography>
                    <a href={single_media?.iframe} target="_blank" rel="noreferrer"><Button variant="contained">View on YouTube</Button></a>
                </CardContent>
            </Drawer>
            <SlimCommentDrawer tracking={{type:"Entertainment", id:single_media?.id}} count={single_media?.replies_count} token={token} id={single_media?.status} openCommentReply={openCommentReplyDrawer} setOpenCommentReply={setOpenCommentReplyDrawer} />

        </Container>
        </MainLoggedInLayout>
    )
}