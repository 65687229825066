export const getToken = async (code) => {
    const response = await fetch('https://makegayfriends.com/oauth/token?grant_type=authorization_code&code='+code+'&client_id='+process.env.REACT_APP_CLIENT_ID+'&client_secret='+process.env.REACT_APP_CLIENT_SECRET+'&scope=read+write&redirect_uri=https://beta.makegayfriends.com', {method:"POST"});
    const data = await response.json();
    return data
}

export const getRegister = async ({token, register_data}) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch('https://makegayfriends.com/api/v1/accounts', {method:"POST", headers, body: JSON.stringify(register_data)});
    const data = await response.json();
    return data
}

export const getLogout = async () => {
    localStorage.clear()
    window.location.reload()
    /*
    const response = await fetch('https://api.bacpac.io/mkgf/logout', { method:"POST", body:JSON.stringify({token:token}) });
    const data = await response.json();
    console.log(response)
    if(response.status === 200){
        console.log(data)
    }
    return data
    */
}

export const verifyAuth = async (token) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch('https://makegayfriends.com/api/v1/accounts/verify_credentials', { headers });
    const data = await response.json();
    return data
}