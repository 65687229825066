import { AllInclusive, Checkroom, Flare, Movie, Spa, Sports, SportsBar, TravelExplore } from "@mui/icons-material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"

export const CategoryPicker = ({category, setCategory}) => {

    let category_types = [
        {label:"Social", icon:<SportsBar />, value:'social'},
        {label:"Wellness", icon:<Spa />, value:'wellness'},
        {label:"Entertainment", icon:<Movie />, value:'entertainment'},
        {label:"Fashion", icon:<Checkroom />, value:'fashion'},
        {label:"Spirituality", icon:<Flare />, value:'spirituality'},
        {label:"Sports & Fitness", icon:<Sports />, value:'sport_fitness'},
        {label:"Travel", icon:<TravelExplore />, value:'travel'},
        {label:"Other", icon:<AllInclusive />, value:'other'},
    ]

    return (
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Categories</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="Category"
            >
                {category_types.map((category, i) =>
                <MenuItem key={i} value={category.value}>
                    <div onClick={() => {
                        setCategory(category.value)
                    }} style={{justifyContent:'space-between', display:'flex', width:'100%'}}>{category.label} {category.icon}</div>
                </MenuItem>
                )}
            </Select>
        </FormControl>
        
    )
}