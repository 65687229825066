let api = process.env.REACT_APP_MKGF_MASTODON_API+'/interest'

export const getAllInterests = async (token) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch(api+"/all" , { headers });
    const data = await response.json();
    return data
}

export const getInterest = async (token, id) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch(api+"/"+id , { headers });
    const data = await response.json();
    
    if(data.interest === null){
        return {auth:data.interest, users:null}
    }
    
    let body = {comments:data.interest.comments}
    const headers2 = { 'Authorization': 'Bearer '+token, 'Content-Type': 'application/json' }
    const response2 = await fetch(api+"/all" , { method:"POST", headers:headers2, body:JSON.stringify(body) });
    const data2 = await response2.json();
    return {auth:data.interest, users:data2.interest}
}

export const createInterest = async ({token, mastodon_id}) => {
    let body = {mastodon_id, comments:[]}
    const headers = { 'Authorization': 'Bearer '+token, 'Content-Type': 'application/json' }
    const response = await fetch(api+"/" , { method:"POST", headers, body:JSON.stringify(body) });
    const data = await response.json();
    return data
}

export const editInterest = async ({token, id, type, interest_details}) => {
    const headers = { 'Authorization': 'Bearer '+token, 'Content-Type': 'application/json' }
    const response = await fetch(api+"/"+id+"/"+type , { method:"PUT", headers, body:JSON.stringify(interest_details) });
    const data = await response.json();
    return data
}

export const deleteInterest = async ({token, id}) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch(api+"/"+id , { method:"DELETE", headers });
    const data = await response.json();
    return data
}