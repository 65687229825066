import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { AddBox, Explore, ViewDay, WavingHand } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { Avatar, Box, Dialog } from '@mui/material';
import { CreatePost } from '../post/CreatePost';
import { MainMenu } from './MainMenu';
import useWindowDimensions from '../../util/isMobile';

export default function MainBottomNavigation() {
  const [value, setValue] = React.useState(null);
  const [open, setOpen] = React.useState(false)
  const [status, setStatus] = React.useState("")
  const [openMenu, setOpenMenu] = React.useState(false)
  let navigate = useNavigate()
  const userDetails = React.useContext(UserContext)
  const { width } = useWindowDimensions()
  return (
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        
      >
        {width > 900 &&
        <BottomNavigationAction onClick={() => {
          setOpen(true)
          }} label="Upload" icon={<AddBox />} />
        }
          <CreatePost token={userDetails?.token} open={open} setOpen={setOpen} status={status} setStatus={setStatus} buttonTitle={"Upload Status"} visibility={'public'} />
        <BottomNavigationAction onClick={() => {
          navigate("/explore")
          }} label="Explore" icon={<Explore />} />
        <BottomNavigationAction onClick={() => {
          navigate("/meet")
          }} label="Meet" icon={<WavingHand />} />
        {width < 900 &&
          <BottomNavigationAction onClick={() => {
          setOpenMenu(true)
          }} label="Menu" icon={<ViewDay />} />
        }
        <Dialog onClose={() => setOpenMenu(false)} open={openMenu}>
          <Box sx={{ width: 250 }} role="presentation" onClick={() => setOpen(!open)}>
            {<MainMenu />}
          </Box>
        </Dialog>
        <BottomNavigationAction onClick={() => {
          navigate("/profile/"+userDetails?.id)
          }} label="Account" icon={<Avatar alt={userDetails?.username} src={userDetails?.avatar} />} />
      </BottomNavigation>
  );
}