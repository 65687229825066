import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { getConversations } from '../../controllers/message/conversation';
import { UserContext } from "../../context/UserContext";
import { Loading } from '../../util/util';

export default function ConversationList() {
  let navigate = useNavigate()
  const userDetails = React.useContext(UserContext)
  let token = userDetails?.token ?? ''
  const { data, refetch } = useQuery({ queryKey: ['getConversations'], queryFn: () => getConversations(token) })
  let conversations = data ?? []
  React.useEffect(() => {
      refetch()
  })

  if(conversations.length < 1){
    return (
      <Loading />
    )
  }
  
  return (
    <>
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {conversations.length > 0 && conversations?.map((item, i) =>
      <React.Fragment key={i}>
      <ListItem alignItems="flex-start" onClick={() => navigate("/messages/"+item.last_status.id+"/"+item?.id)}>
        <ListItemAvatar>
          <Avatar alt={item.last_status.account.username} src={item.last_status.account.avatar} />
        </ListItemAvatar>
        <ListItemText
          primary={item.last_status.content.replaceAll(/(<([^>]+)>)/ig, '').replaceAll("&#39;", "'")}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {item.last_status.account.acct}
              </Typography>
              {" — "+new Date(item.last_status.created_at).toDateString()}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      </React.Fragment>
      )}
    </List>
    <Divider style={{height:10}} />
    </>
  );
}