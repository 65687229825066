import { Box, Button, ButtonGroup, Card, CardContent, CardHeader, Grid, Tab, Tabs } from "@mui/material"
import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout"
import { useNavigate } from "react-router"
import { useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { UserContext } from "../../context/UserContext";
import { searchAllForums } from "../../controllers/forum/forum";
import { para_label } from "../community/list";
import { CategoryDisplay } from "../../components/category.display";
import { SearchWidget } from "../../components/search.widget";
import CategoryFilter from "../../components/category.filter";
import { Tune } from "@mui/icons-material";

export const CommunityCard = ({card_details, navigate}) => {
    return(
        <div style={{zIndex:0, position:"relative", cursor:'pointer'}} onClick={() => navigate("/forum/show/"+card_details?._id)}>      
            <div style={{backgroundSize:'cover', border:"1px solid", filter:"alpha(opacity=40)", height:120, width:"100%"}}></div>
            <div style={{backgroundColor:'black', opacity:"0.6", position:"absolute", top:0, zIndex:"100", left:0, height:120, width:"100%"}}>

            </div>
            <div style={{padding:5, position:"absolute", top:0, zIndex:"100", left:0, height:120, width:"100%", color:'white'}}>
                <p style={{...para_label, fontSize:12}}>{card_details.title.split("Original post: ")[0].slice(0, 155)}{(card_details.title.length - 150) > 156 && " ..."}</p>
                <CategoryDisplay label={card_details.category} hideLabel={true} />
            </div>
        </div>
    )
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export const ListForum = () => {
    const userDetails = useContext(UserContext)
    let token = userDetails?.token
    const [title, setTitle] =  useState('')
    const [showCatFilter, setShowCatFilter] = useState(false)
    const [page_number, setPageNum] = useState(0)
    const [category, setCategory] = useState('')
    const [categories, setCategories] = useState([])
    let limit = 14
    const { data:forums, refetch } = useQuery({ queryKey: ['searchAllForums'], queryFn: () => searchAllForums({token, title, page_number, category, limit}) })
    useEffect(() => {
        refetch()
    })

    let listForumTopics = useMemo(() => forums?.forum ?? [],[forums?.forum])
    const [allForumTopics, setAllForumTopics] = useState([])
    const [tempForumTopics, setTempForumTopics] = useState([])

    useEffect(() => {
        listForumTopics.filter(item => setTempForumTopics(allForumTopics => [...allForumTopics, item]))
        let localCats = []
        listForumTopics.filter(item => item.category && !localCats.includes(item.category) && localCats.push(item.category))
        setCategories(localCats)
    }, [listForumTopics])

    useEffect(() => {
        let jsonObject = tempForumTopics.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);

        setAllForumTopics(uniqueArray)
    }, [tempForumTopics])
    
    let navigate = useNavigate()
    const [value, setValue] = useState(1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
      setPageNum(0)
      if(title || category){
        setAllForumTopics([])
        setTempForumTopics([])
      }
    }, [title, category])
    
    
    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10;
        if (bottom && listForumTopics.length === limit) { 
            setTimeout(() => {
                setPageNum(page_number+1)
            }, 100);
        }
      }
    return (
        <MainLoggedInLayout>
            
            <Card onScroll={handleScroll}  style={{overflowY: 'scroll', maxHeight: '95vh', scrollbarWidth:'none'}}>
                <CardHeader 
                    title={value === 0 ? "My Topics" : "All Topics"} 
                    subheader={<Button variant="contained" onClick={() => navigate("/forum/create")}>Create A Topic</Button>}
                    />

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="My Topics" {...a11yProps(0)} />
                    <Tab label="All Topics" {...a11yProps(1)} />
                </Tabs>
                </Box>

                <div style={{padding:10}}>
                    <SearchWidget search={title} setSearch={setTitle} />
                    <ButtonGroup style={{marginLeft:10}} variant="contained" aria-label="Basic button group">
                        <Button onClick={() => setShowCatFilter(!showCatFilter)}>Category <Tune /></Button>
                    </ButtonGroup>
                    {showCatFilter && 
                    <div>
                        <CategoryFilter catgories={categories} category={category} setCategory={setCategory} />
                    </div>
                    }
                </div>

                <CustomTabPanel value={value} index={0}>

                    <CardContent style={{padding:0}}>
                        <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {allForumTopics.map((_, index) => (
                           _.author?.includes(userDetails?.id) &&
                            <Grid item xs={2} sm={4} md={6} key={index}>
                                <CommunityCard card_details={_} navigate={navigate} userDetails={userDetails} />
                            </Grid>
                        ))}
                        </Grid>
                    </CardContent>

                </CustomTabPanel>
                
                <CustomTabPanel value={value} index={1}>

                    <CardContent style={{padding:0}}>
                        <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {allForumTopics.map((_, index) => (
                            <Grid item xs={2} sm={4} md={6} key={index}>
                                <CommunityCard card_details={_} navigate={navigate} userDetails={userDetails} />
                            </Grid>
                        ))}
                        </Grid>
                    </CardContent>

                </CustomTabPanel>

            </Card>
        </MainLoggedInLayout>
    )
}