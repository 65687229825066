import { useParams } from "react-router"
import PostCard from "../../components/post/PostCard"
import { getSinglePost } from "../../controllers/posts/posts"
import { useQuery } from "@tanstack/react-query"
import { useContext, useEffect } from "react"
import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout"
import { CircularProgress } from "@mui/material"
import { UserContext } from "../../context/UserContext"

export const PostShow = () => {
    const { id } = useParams()
    const userDetails = useContext(UserContext)
    let token = userDetails?.token ?? ''
    const { data:content, refetch, isLoading } = useQuery({ queryKey: ['getSinglePost'], queryFn: () => getSinglePost(id, token) })
    useEffect(() => {
        refetch()
    })

    if(isLoading){
        return (
            <CircularProgress />
        )
    }

    return (
        <MainLoggedInLayout>
        <div style={{display:'flex', justifyContent:'center', marginTop:5}}>
            <PostCard content={content} token={token} />
        </div>
        </MainLoggedInLayout>
    )
}