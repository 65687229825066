import { Button } from "@mui/material"
import { QueryClient, useMutation } from "@tanstack/react-query"
import { useContext } from "react"
import { UserContext } from "../../context/UserContext"
import { editEvent } from "../../controllers/event/event"

export const RSVPEventButton = ({event_details}) => {
    let card_details = event_details
    const userDetails = useContext(UserContext)

    const queryClient = new QueryClient()
    const mutationEdit = useMutation({
        mutationFn: editEvent,
        onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['editEvent'] })
        },
    })
    

    const sendRSVPToEvent = () => {
        mutationEdit.mutate({
            id:card_details?._id, 
            body:{RSVP:[...card_details.RSVP, userDetails?.id]},
            token:userDetails?.token
        })
    }

    return (
        <Button variant="contained" color="success" onClick={sendRSVPToEvent}>RSVP</Button>
    )
}