let api = process.env.REACT_APP_MKGF_MASTODON_API+'/user'

export const trackingInterest = async ({token, mastodon_id, trackingInterest}) => {
    let body = { mastodon_id, trackingInterest } 
    const headers = { 'Authorization': 'Bearer '+token, 'Content-Type': 'application/json' }
    const response = await fetch( api+'/', { method:"POST", headers, body:JSON.stringify(body) });
    const data = await response.json();
    return data
}

export const getMongooseUserDetails = async (token, mastodon_id) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch( api+'/'+mastodon_id, { headers });
    const data = await response.json();
    return data
}