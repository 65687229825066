import { Box, Button, ButtonGroup, Card, CardContent, CardHeader, Grid, Tab, Tabs } from "@mui/material"
import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout"
import { useNavigate } from "react-router"
import { useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { UserContext } from "../../context/UserContext";
import { searchAllEvents } from "../../controllers/event/event";
import { para_label } from "../community/list";
import { CategoryDisplay } from "../../components/category.display";
import { SearchWidget } from "../../components/search.widget";
import CategoryFilter from "../../components/category.filter";
import LocationFilter from "../../components/location.filter";
import { ShareLocation, Tune } from "@mui/icons-material";

export const CommunityCard = ({card_details, navigate}) => {
    return(
        <div style={{zIndex:0, position:"relative", cursor:'pointer'}} onClick={() => navigate("/event/show/"+card_details?._id)}>      
            <div style={{backgroundSize:'cover', backgroundImage:"url('"+card_details.header_img+"')", filter:"alpha(opacity=40)", height:170, width:"100%"}}></div>
            <div style={{backgroundColor:'black', opacity:"0.6", position:"absolute", top:0, zIndex:"100", left:0, height:170, width:"100%"}}>

            </div>
            <div style={{padding:5, position:"absolute", top:0, zIndex:"100", left:0, height:170, width:"100%", color:'white'}}>
                <p style={{...para_label, color:'#00ff40', fontSize:16}}>{card_details.title}</p>
                <p style={para_label}>{new Date(card_details.start_date_time).toDateString()}</p>
                <p style={para_label}>{card_details.location.formatted_address}</p>
                <p style={para_label}>{card_details.RSVP.length} joined</p>
                <CategoryDisplay label={card_details.category} hideLabel={true} />
            </div>
        </div>
    )
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export const ListEvent = () => {
    const userDetails = useContext(UserContext)
    let token = userDetails?.token
    const [showCatFilter, setShowCatFilter] = useState(false)
    const [showLocFilter, setShowLocFilter] = useState(false)
    const [title, setTitle] =  useState('')
    const [page_number, setPageNum] = useState(0)
    const [miles, setMiles] = useState(10);
    const [lat, setLat] = useState("")
    const [lng, setLng] = useState("")
    const [category, setCategory] = useState('')
    const [categories, setCategories] = useState([])
    let limit = 8
    const { data:events, refetch } = useQuery({ queryKey: ['searchAllEvents'], queryFn: () => searchAllEvents({token, title, page_number, category, limit, lat, lng, miles}) })
    useEffect(() => {
        refetch()
    })
    let listAllEvents = useMemo(() => events?.event ?? [],[events?.event])
    const [allEvents, setAllEvents] = useState([])
    const [tempEvents, setTempEvents] = useState([])

    useEffect(() => {
        listAllEvents.filter(item => setTempEvents(allEvents => [...allEvents, item]))
        let localCats = []
        listAllEvents.filter(item => item.category && !localCats.includes(item.category) && localCats.push(item.category))
        setCategories(localCats)
    }, [listAllEvents])

    useEffect(() => {
        let jsonObject = tempEvents.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);

        setAllEvents(uniqueArray)
    }, [tempEvents])
    

    useEffect(() => {
      setPageNum(0)
      if(title || category || lat){
        setAllEvents([])
        setTempEvents([])
      }
    }, [title, category, lat])

    let navigate = useNavigate()
    const [value, setValue] = useState(1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    const handleScroll = (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10;
      if (bottom && listAllEvents.length === limit) { 
        setTimeout(() => {
            setPageNum(page_number+1)
        }, 100);
    }
    }
    return (
        <MainLoggedInLayout>
            
            <Card onScroll={handleScroll}  style={{overflowY: 'scroll', maxHeight: '95vh', scrollbarWidth:'none'}}>
                <CardHeader 
                    title={value === 0 ? "My Events" : "All Events"} 
                    subheader={<Button variant="contained" onClick={() => navigate("/event/create/"+null)}>Create A Event</Button>}
                    />

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="My Events" {...a11yProps(0)} />
                    <Tab label="All Events" {...a11yProps(1)} />
                </Tabs>
                </Box>

                <div style={{padding:10}}>
                    <SearchWidget search={title} setSearch={setTitle} />
                    <ButtonGroup style={{marginLeft:10}} variant="contained" aria-label="Basic button group">
                        <Button onClick={() => setShowCatFilter(!showCatFilter)}>Category <Tune /></Button>
                        <Button onClick={() => setShowLocFilter(!showLocFilter)}>Location <ShareLocation /></Button>
                    </ButtonGroup>
                    {showCatFilter && 
                        <div>
                            <CategoryFilter catgories={categories} category={category} setCategory={setCategory} />
                        </div>
                    }
                    
                    {showLocFilter && 
                        <LocationFilter miles={miles} setMiles={setMiles} setLat={setLat} setLng={setLng} />
                    }
                </div>

                <CustomTabPanel value={value} index={0}>

                    <CardContent style={{padding:0}}>
                        <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {allEvents.map((_, index) => (
                           _.author?.includes(userDetails?.id) &&
                            <Grid item xs={2} sm={4} md={6} key={index}>
                                <CommunityCard card_details={_} navigate={navigate} userDetails={userDetails} />
                            </Grid>
                        ))}
                        </Grid>
                    </CardContent>

                </CustomTabPanel>
                
                <CustomTabPanel value={value} index={1}>

                    <CardContent style={{padding:0}}>
                        <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {allEvents.map((_, index) => (
                            <Grid item xs={2} sm={4} md={6} key={index}>
                                <CommunityCard card_details={_} navigate={navigate} userDetails={userDetails} />
                            </Grid>
                        ))}
                        </Grid>
                    </CardContent>

                </CustomTabPanel>

            </Card>
        </MainLoggedInLayout>
    )
}