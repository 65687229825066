import PostCard from "../../components/post/PostCard.jsx"
import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout.jsx"
import { useContext, useEffect, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { UserContext } from "../../context/UserContext.js"
import { homeTimeline } from "../../controllers/feed/feed.js"
import { Loading } from "../../util/util.js"

export const Feed = () => {
    const userDetails = useContext(UserContext)
    let token = userDetails?.token ?? ''
    const [max_id, setMaxID] = useState(null)
    const { data:timeline, refetch } = useQuery({ queryKey: ['homeTimeline'], queryFn: () => homeTimeline(token, max_id) })
    const [media, setMedia] = useState([])
    
    const isInMedia = (id) => {
      let doesItExsit = media?.filter(x => x.id === id)
      return doesItExsit.length > 0 ?  true :  false
    }
    
    useEffect(() => {
      if(timeline?.length > 0){
        timeline?.filter(item => isInMedia(item.id) ? null : setMedia(media => [...media, item]))
      }
      refetch()
    })
    
    const handleScroll = (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10;
      if (bottom) { 
        if(timeline?.length > 0){
            setMaxID(timeline[timeline?.length - 1].id)

        }
      }
    }
    
    if(media?.length < 1){
        return (
            <MainLoggedInLayout>
              <Loading />
            </MainLoggedInLayout>
        )
    }
    return(
        <MainLoggedInLayout>
            <div onScroll={handleScroll}  style={{overflowY: 'scroll', maxHeight: '95vh', scrollbarWidth:'none', margin:'auto', padding:10}}  >
                {media.length > 0 && media?.map((item, i) => 
                    (item?.content || item?.media_attachments?.length > 0) &&
                    <div key={i}  style={{margin:'auto', marginTop:'1vh'}}>
                        <PostCard content={item} token={token} />
                    </div>
                )}
            </div>
        </MainLoggedInLayout>
    )
}