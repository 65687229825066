import { QueryClient, useMutation } from "@tanstack/react-query"
import { uploadMedia } from "../../../controllers/posts/posts"
import { Image } from "@mui/icons-material"
import { Button, CircularProgress, Grid, styled } from "@mui/material"
import { useState } from "react";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const PostMedia = ({token, imageUrls, setImageUrls, media_ids, setMediaIds}) => {
    const [isLoading, setIsLoading] = useState(false)
    const queryClient = new QueryClient()
    const mutation = useMutation({
        mutationFn: uploadMedia,
        onSuccess: (data) => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['uploadMedia'] })
        setImageUrls(imageUrls => [...imageUrls, data.url])
        setMediaIds(media_ids => [...media_ids, data.id])
        setIsLoading(false)
        },
    })

    const uploadMastodonMedia = (image) => {
        setIsLoading(true)
        mutation.mutate({
            image, 
            token
        })
    }
    return (
        <>
        {!isLoading ?
            <>
            <Grid container style={{justifyContent:'space-between'}}>
                {imageUrls?.map((url, i) =>
                    <Grid key={i} item> 
                        <img width={100} src={url} key={i} alt={'status'} />
                    </Grid>
                )}
            </Grid>
            <Button
                component="label"
                role={undefined}
                tabIndex={-1}
                startIcon={<Image style={{fontSize:40}} />}
            >
                <VisuallyHiddenInput type="file" accept="image/*" onChange={(value) => uploadMastodonMedia(value.target.files[0])} />
            </Button>
            </>
        :
        <div style={{display:'flex', alignItems:'center', margin: 5}}>
            <CircularProgress />
            Loading Image
        </div>
        }
        </>
    )
}