import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { Input, InputAdornment } from '@mui/material';
import { Add, Comment } from '@mui/icons-material';
import { QueryClient, useMutation } from "@tanstack/react-query";
import { createComment } from '../../controllers/posts/comments';
import { UserContext } from '../../context/UserContext';

export default function CommentReplyDrawer({id, orgin_id, token, content, setContent, open, setOpen, getAPIComments}) {

  const userDetails = React.useContext(UserContext)
  const [reply_id, setReplyId] = React.useState(id)

  React.useEffect(() => {
    setReplyId(id)
  }, [id])

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const queryClient = new QueryClient()
  const mutation = useMutation({
    mutationFn: createComment,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['createComment'] })
      getAPIComments()
      setOpen(!open)
      document.getElementById("content").value = ""
      setReplyId(id)
    },
  })

  const send_reply = () => {
    mutation.mutate({
      body:{
        status:document.getElementById("content").value,
        in_reply_to_id:reply_id,
        visibility:"unlisted"
      },
      token
    })
  }
  
  const DrawerList = (
    <div style={{justifyContent:'center', display:'flex', margin:20}}>
        <Input
            id="content"
            placeholder='Comment'
            multiline
            endAdornment={
                <InputAdornment position="end">
                    <Button onClick={send_reply}><Comment /></Button>
                </InputAdornment>
            }
        />
    </div>
  );

  return (
    <>
      <Button 
      color='primary'
      variant="contained"
      onClick={() => {
        if(userDetails?.id){ 
        setOpen(true)
        setReplyId(orgin_id)
        setContent('')
        } else {
        window.location.replace('https://makegayfriends.com/oauth/authorize?client_id='+process.env.REACT_APP_CLIENT_ID+'&scope=read+write&redirect_uri=https://beta.makegayfriends.com&response_type=code')
        }
      }}><Add /> Leave Comment</Button>
      <Drawer anchor='bottom' open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </>
  );
}