import CategoryFilter from "../../components/category.filter"
import { useContext, useEffect, useState } from "react"
import { searchAllMedia } from "../../controllers/entertainment/entertainment"
import { UserContext } from "../../context/UserContext"
import { useQuery } from "@tanstack/react-query"
import { Button, Card, CardContent, Grid, TextField, Typography } from "@mui/material"
import { useNavigate } from "react-router"
import { OpenInFull, Tune } from "@mui/icons-material"
import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout"
import { CategoryDisplay } from "../../components/category.display"
import { generalCategories } from "../../util/util"

const MediaDisplay = (props) => {
    return (
        <>
        <Card>
            <CardContent>
                <Typography>{props.title}</Typography>
                <CategoryDisplay label={props.category} />

                <iframe 
                    style={{height:150, width:'100%'}}
                    src={props.iframe}
                    title="Media"
                />
                <div onClick={() => props.navigate("/entertainment/show/"+props.id)} style={{marginTop:-40}}>
                    <Button onClick={() => props.navigate("/entertainment/show/"+props.id)}  fullWidth variant="contained" >
                        <OpenInFull /> Fullscreen
                    </Button>
                </div>

            </CardContent>
        </Card>
        </>
    )
}

export const EntertainmentList = () => {
    const userDetails = useContext(UserContext)
    let token = userDetails?.token

    let navigate = useNavigate()

    const [category, setCategory] = useState('')
    const [title, setTitle] = useState('')
    const [page_number, setPageNum] = useState('')
    const [filter, setFilter] = useState(false)
    
    let limit = 14
    const { data:media_list, refetch } = useQuery({ queryKey: ['searchAllMedia'], queryFn: () => searchAllMedia({token, title, page_number, category, limit}) })
    const [allMedia, setAllMedia] = useState([])

    useEffect(() => {
      refetch()
    }, [title, refetch, category, page_number])
    
    useEffect(() => {
        media_list && media_list?.entertainment?.filter(item => setAllMedia(media => [...media, item]))
    }, [media_list])
    
    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10;
        if (bottom && media_list?.entertainment.length === limit) { 
          setTimeout(() => {
              setPageNum(page_number+1)
          }, 100);
        }
    }
    
    return (
        <MainLoggedInLayout>
            {userDetails?.roles?.includes("content_creator") &&
                <div style={{flexDirection:'row', justifyContent:'flex-end', margin:15}}>
                    <Button variant="contained" onClick={() => navigate("/entertainment/create")}>Upload Content</Button>
                </div>
            }

            <Button variant="contained" style={{marginTop:15}} onClick={() => setFilter(!filter)}>{filter ? "Hide" : "Show"} Filter <Tune color={'white'} /></Button>
            {filter &&
            <Card>
                <TextField style={{padding:10}} value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Search Media" />

                <CategoryFilter catgories={generalCategories} category={category} setCategory={setCategory} />
            </Card>
            }

            <Grid onScroll={handleScroll} style={{overflowY: 'scroll', maxHeight: '95vh', scrollbarWidth:'none'}} container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 9, md: 12 }}>

                {allMedia?.map((media, i) =>
                    <Grid key={i} item xs={2} sm={3} md={6}>
                        <MediaDisplay id={media._id} navigate={navigate} category={media.category} video_length={media.video_length} title={media.title} iframe={media.iframe} desc={media.desc} />
                    </Grid>
                )}

            </Grid>
        </MainLoggedInLayout>
    )
}