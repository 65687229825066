import { QueryClient, useMutation } from "@tanstack/react-query"
import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout"
import { createForum } from "../../controllers/forum/forum"
import { useContext, useState } from "react"
import { UserContext } from "../../context/UserContext"
import { Button, Card, CardActions, CardContent, CardHeader, List, ListItem, TextField } from "@mui/material"
import { PostMedia } from "../../components/post/card/PostMedia"
import { useNavigate } from "react-router"
import { CategoryPicker } from "../../components/category.picker"
import { createComment } from "../../controllers/posts/comments"

export const CreateForum = () => {
    const userDetails = useContext(UserContext)
    const [media_ids, setMediaIds] = useState([])
    const [imageUrls, setImageUrls] = useState([])
    const [status, setStatus] = useState('')
    let token = userDetails?.token
    const [category, setCategory] = useState('');

    let navigate = useNavigate()

    const queryClient = new QueryClient()
    const mutation = useMutation({
        mutationFn: createForum,
        onSuccess: (data) => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['createForum'] })
        navigate("/forum/show/"+data.forum._id)
        },
    })
    
    const sendCreateForum = (status_id) => {
        mutation.mutate({
          title:status,
          status:status_id,
          category,
          author:[userDetails?.id],
          token:userDetails?.token
        })
    }

    const mutationComment = useMutation({
      mutationFn: createComment,
      onSuccess: (data) => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['createComment'] })
        sendCreateForum(data.id)
      },
    })
  
    const send_reply = () => {
        mutationComment.mutate({
        body:{
            status,
            visibility:"unlisted",
            media_ids
        },
        token
      })
    }

    return (
        <MainLoggedInLayout>
            <Card>
                <CardHeader
                    title="Create a topic!"
                    subheader={new Date().toDateString()}
                />
                <CardContent>
            
                    <List>
                        <div style={{marginLeft:20, marginRight:20}}>
                            <TextField value={status} onChange={(e) => setStatus(e.target.value)} fullWidth multiline id="outlined-basic" label='Forum Topic' variant="outlined" />
                            <PostMedia imageUrls={imageUrls} setImageUrls={setImageUrls} media_ids={media_ids} setMediaIds={setMediaIds} token={token} />
                        </div>

                        <ListItem>
                            <CategoryPicker category={category} setCategory={setCategory} />
                        </ListItem>
                    </List>
                    
                </CardContent>
                <CardActions>
                    <Button fullWidth variant="contained" onClick={send_reply}>Create Topic!</Button>
                </CardActions>
      
            </Card>
            
        </MainLoggedInLayout>
    )
}