let api = process.env.REACT_APP_MKGF_MASTODON_API+'/community'

export const getAllCommunities = async (token) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch(api+"/all" , { headers });
    const data = await response.json();
    return data
}

export const searchAllCommunities = async ({token, title, page_number, category, limit}) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch(api+"/search?title="+title+"&page_number="+page_number+"&limit="+limit+"&category="+(category ?? ''), { headers });
    const data = await response.json();
    return data
}

export const getCommunity = async (token, id) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch(api+"/"+id , { headers });
    const data = await response.json();
    return data
}

export const createCommunity = async ({token, title, header_img, leader, category}) => {
    let body = {title, header_img, leader, category}
    const headers = { 'Authorization': 'Bearer '+token, 'Content-Type': 'application/json' }
    const response = await fetch(api+"/" , { method:"POST", headers, body:JSON.stringify(body) });
    const data = await response.json();
    return data
}

export const editCommunity = async ({token, id, body}) => {
    const headers = { 'Authorization': 'Bearer '+token, 'Content-Type': 'application/json' }
    const response = await fetch(api+"/"+id , { method:"PUT", headers, body:JSON.stringify(body) });
    const data = await response.json();
    return data
}

export const deleteCommunity = async ({token, id}) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch(api+"/"+id , { method:"DELETE", headers });
    const data = await response.json();
    return data
}