import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Button, Container, Menu, MenuItem, TextField } from '@mui/material';
import useWindowDimensions from '../../util/isMobile';
import { MoreVert } from '@mui/icons-material';
import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { createComment, getComments } from '../../controllers/posts/comments';
import { getSinglePost } from '../../controllers/posts/posts';
import { UserContext } from '../../context/UserContext';
import { DeleteConversation } from './ConversationActions';
import { useParams } from 'react-router';

export const MessageItem = ({item, last_post}) => {
  const myRef = React.useRef(null)
  const userDetails = React.useContext(UserContext)
  React.useEffect(() => {
    if(myRef.current){
      myRef.current.scrollIntoView()
    }
  })
  return (
  item?.account?.id !== userDetails?.id ?
  <ListItem ref={last_post ? myRef : null}>
    <ListItemAvatar>
        <Avatar alt={item?.account?.username} src={item?.account?.avatar} />
    </ListItemAvatar>
    <ListItemText primary={item?.content?.replaceAll(/(<([^>]+)>)/ig, '').replaceAll("&#39;", "'")} secondary={new Date(item?.created_at).toDateString()} />
  </ListItem>
  :
  <ListItem ref={last_post ? myRef : null} style={{textAlign:'right'}} secondaryAction={<Avatar alt={item?.account?.username} src={item?.account?.avatar} />}>
    <ListItemText style={{marginRight:20}} primary={item?.content?.replaceAll(/(<([^>]+)>)/ig, '').replaceAll("&#39;", "'")} secondary={new Date(item?.created_at).toDateString()} />
  </ListItem>
  )
}

export function SingleConversation({init_id}) {
    const { width } = useWindowDimensions()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [id, setID] = React.useState(init_id)
    const [content, setContent] = React.useState("")
    const open = Boolean(anchorEl);
    const userDetails = React.useContext(UserContext)
    let token = userDetails?.token ?? ''
    const { data, refetch } = useQuery({ queryKey: ['getComments'], queryFn: () => getComments(id, token) })
    let conversation = data ?? []
    const { data:post, refetch:single_post_fetch  } = useQuery({ queryKey: ['getSinglePost'], queryFn: () => getSinglePost(id, token) })
    const single_post = post ?? {}
    const { conversation_id } = useParams()
    React.useEffect(() => {
        refetch()
        single_post_fetch()
    })
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
      if(conversation?.descendants?.length > 0){
        setID(conversation.descendants[0].id)
      }
    }, [setID, conversation?.descendants])

  const queryClient = new QueryClient()
  const mutation = useMutation({
    mutationFn: createComment,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['createComment'] })
      setContent("")
    },
  })

  const send_reply = (comment_id) => {
    mutation.mutate({
      body:{
        status:content,
        in_reply_to_id:comment_id,
        visibility:"direct"
      },
      token
    })
  }
    
  return (
    <>
    <div style={{backgroundColor:'white', textAlign:'right', position:'sticky', top:0, zIndex:1}}>
      <Button
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        OPTIONS <MoreVert />
      </Button>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
          <MenuItem onClick={handleClose}>
            <DeleteConversation token={token} id={conversation_id} />
          </MenuItem>
      </Menu>
    </div>
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {conversation?.ancestors?.map((item, i) => <MessageItem item={item} last_post={false} key={i} />)}
      <MessageItem item={single_post} last_post={true} />
    </List>
    <Container style={{display:'flex', flexDirection:'row', position: 'sticky', bottom:width > 900 ? 0 : 50, backgroundColor:'white', padding:5}}>
        <TextField value={content} onChange={(e) => setContent(e.target.value)} multiline fullWidth id="outlined-basic" label="Reply" variant="outlined" />
        <Button onClick={() => send_reply(single_post?.id)} variant='contained'>Submit</Button>
    </Container>
    </>
  );
}