let api = process.env.REACT_APP_MKGF_MASTODON_API+'/media'

export const getAllMedia = async (token) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch(api+"/all" , { headers });
    const data = await response.json();
    return data
}

export const searchAllMedia = async ({token, title, page_number, category, limit}) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch(api+"/search?title="+title+"&page_number="+page_number+"&limit="+limit+"&category="+(category ?? ''), { headers });
    const data = await response.json();
    return data
}

export const getMedia = async (token, id) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch(api+"/"+id , { headers });
    const data = await response.json();

    const responsePost = await fetch('https://makegayfriends.com/api/v1/statuses/'+data.entertainment.status, { headers });
    const dataPost = await responsePost.json();
    return {...data, ...dataPost}
}

export const createMedia = async ({token, body}) => {
    const headers = { 'Authorization': 'Bearer '+token, 'Content-Type': 'application/json' }
    const response = await fetch(api+"/" , { method:"POST", headers, body:JSON.stringify(body) });
    const data = await response.json();
    return data
}

export const editMedia = async ({token, id, body}) => {
    const headers = { 'Authorization': 'Bearer '+token, 'Content-Type': 'application/json' }
    const response = await fetch(api+"/"+id , { method:"PUT", headers, body:JSON.stringify(body) });
    const data = await response.json();
    return data
}

export const deleteMedia = async ({token, id}) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch(api+"/"+id , { method:"DELETE", headers });
    const data = await response.json();
    return data
}