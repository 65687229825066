export const getSinglePost = async (id, token) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch('https://makegayfriends.com/api/v1/statuses/'+id, { headers });
    const data = await response.json();
    return data
}

export const getMultiPost = async ({ids, token}) => {
    console.log(ids)
    const headers = { 'Authorization': 'Bearer '+token }
    const statusIds = ['112862007667034996', '112854411777751641']
      // Create URLSearchParams object
  const params = new URLSearchParams();
  statusIds.forEach(id => params.append('id[]', id));
  console.log('https://makegayfriends.com/api/v1/statuses?'+params.toString())
    const response = await fetch('https://makegayfriends.com/api/v1/statuses?'+params.toString(), { headers });
    console.log(response)
    const data = await response.json();
    return data
}

export const reblogSinglePost = async ({id, token, reblog}) => {
    const headers = { 'Authorization': 'Bearer '+token }
    let fetch_url = reblog ? 'https://makegayfriends.com/api/v1/statuses/'+id+'/reblog' : 'https://makegayfriends.com/api/v1/statuses/'+id+'/unreblog'
    const response = await fetch(fetch_url, { method:"POST", headers });
    const data = await response.json();
    return data
}

export const favouriteSinglePost = async ({id, token, favourite}) => {
    const headers = { 'Authorization': 'Bearer '+token }
    let fetch_url = favourite ? 'https://makegayfriends.com/api/v1/statuses/'+id+'/favourite' : 'https://makegayfriends.com/api/v1/statuses/'+id+'/unfavourite'
    const response = await fetch(fetch_url, { method:"POST", headers });
    const data = await response.json();
    return data
}

export const deleteSinglePost = async ({id, token}) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch('https://makegayfriends.com/api/v1/statuses/'+id, { method:"DELETE", headers });
    const data = await response.json();
    return data
}

export const uploadMedia = async ({image, token}) => {
    let formData = new FormData()
    formData.append('file', image);
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch('https://makegayfriends.com/api/v2/media', { method:"POST", headers, body:formData });
    const data = await response.json();
    return data
}