import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Button, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { QueryClient, useMutation } from '@tanstack/react-query';
import { editCommunity } from '../controllers/community/community';
import { UserContext } from '../context/UserContext';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function MembersPopUpList({community_id, leaders, title, members, open, setOpen}) {
    const userDetails = React.useContext(UserContext)
    let navigate = useNavigate()

    const handleClose = () => {
        setOpen(false);
    };

    const queryClient = new QueryClient()
    const mutationEdit = useMutation({
        mutationFn: editCommunity,
        onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['editCommunity'] })
        },
    })

    const handleRequest = (member_id) => {
        mutationEdit.mutate({
            id:community_id, 
            body:{member:members?.includes(item => item !== member_id)},
            token: userDetails?.token
        })
    }

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
            <List dense sx={{ width: 200, bgcolor: 'background.paper' }}>
            {members?.map(((member, i) => {
                return (
                <ListItem
                    key={i}
                    secondaryAction={
                        <>
                        {leaders?.includes(userDetails?.id) &&
                        <Button onClick={() => handleRequest(member.id)} color='error'><Delete /></Button>
                        }
                        </>
                    }
                    disablePadding
                >
                    <ListItemButton>
                    <ListItemAvatar>
                        <Avatar
                        alt={`Avatar`}
                        src={member.avatar}
                        onClick={() => navigate("/profile/"+member.id)}
                        />
                    </ListItemAvatar>
                    <ListItemText id={"account"} primary={"@"+member.username} />
                    </ListItemButton>
                </ListItem>
                );
            }))}
            </List>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}