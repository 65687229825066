import { Button } from "@mui/material"
import { QueryClient, useMutation } from "@tanstack/react-query"
import { deleteConversation } from "../../controllers/message/conversation"
import { useNavigate } from "react-router"

export const DeleteConversation = ({token, id}) => {
    let navigate = useNavigate()
    const queryClient = new QueryClient()
    const mutation = useMutation({
        mutationFn: deleteConversation,
        onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['delete_conversation'] })
        navigate("/messages")
        },
    })

    const delete_conversation = () => {
        mutation.mutate({
            token, 
            id
        })
    }
    return (
        <Button onClick={delete_conversation} variant='contained' color='error'>Delete</Button>
    )
}