import React, { createContext, useEffect, useState } from "react";

// Create two context:
// UserContext: to query the context state
// UserDispatchContext: to mutate the context state
const UserContext = createContext(undefined);
const UserDispatchContext = createContext(undefined);

// A "provider" is used to encapsulate only the
// components that needs the state in this context
function UserProvider({ children }) {
  const [userDetails, setUserDetails] = useState({
    token: ""
  });
  const [token, setToken] = useState('')
  const [account, setAccount] = useState({})

  const stored_token = localStorage.getItem('token') ? localStorage.getItem('token') : ''

  useEffect(() => {
    setToken(stored_token)
    let stored_account = localStorage.getItem('user')
    const local_account = stored_account ? JSON.parse(stored_account) : {}
    setAccount(local_account)
  }, [stored_token])
  

  useEffect(() => {
    setUserDetails({token, ...account})
  }, [setUserDetails, token, account])
  

  return (
    <UserContext.Provider value={userDetails}>
      <UserDispatchContext.Provider value={setUserDetails}>
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
}

export { UserProvider, UserContext, UserDispatchContext };