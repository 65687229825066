import { Avatar, AvatarGroup, Button, Card, CardContent, CardHeader, Grid, IconButton, Menu, MenuItem } from "@mui/material"
import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout"
import { useContext, useEffect, useMemo, useState } from "react"
import { UserContext } from "../../context/UserContext"
import { useNavigate, useParams } from "react-router"
import { useQuery } from "@tanstack/react-query"
import { MoreVert } from "@mui/icons-material"
import MembersPopUpList from "../../components/members_popup.list"
import { getEvent } from "../../controllers/event/event"
import { CategoryDisplay } from "../../components/category.display"
import SlimCommentDrawer from "../../components/post/SlimCommentDrawer"
import { RSVPEventButton } from "../../components/event/rsvp_event.button"
import { MediaDisplayGallery } from "../../components/media_display.gallery.jsx"
import { getAccount } from "../../controllers/accounts/accounts.js"

export const ShowEvent = () => {
    const [leaders, setLeaders] = useState([])
    const [members, setMembers] = useState([])
    const { id } = useParams()
    const userDetails = useContext(UserContext)
    let token = userDetails?.token
    const { data:event, refetch } = useQuery({ queryKey: ['getSingleEvent'], queryFn: () => getEvent(token, id) })

    let single_event = useMemo(() => event?.event ?? {} , [event?.event]) 

    useEffect(() => {
        single_event?.author?.forEach(async (member) => {
            const acct = await getAccount(member, token)
            setLeaders(ids => [...ids, acct])
        })
        single_event?.RSVP?.forEach(async (memberReq) => {
            const acct = await getAccount(memberReq, token)
            setMembers(ids => [...ids, acct])
        })
    }, [single_event, token])

    const [openCommentReplyDrawer, setOpenCommentReplyDrawer] = useState(false)

    let navigate = useNavigate()
    useEffect(() => {
        refetch()
    })

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const [membersOpen, setMembersOpen] = useState(false)

    return (
        <MainLoggedInLayout>
            <Card>
                <CardHeader
                    title={single_event?.title}
                    subheader={ 
                    <div style={{float:'right', marginTop:-35}}>
                        {!single_event?.RSVP?.includes(userDetails?.id) &&
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={openMenu ? 'long-menu' : undefined}
                          aria-expanded={openMenu ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={handleClick}
                        >
                          <MoreVert />
                        </IconButton>}
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            'aria-labelledby': 'long-button',
                          }}
                          anchorEl={anchorEl}
                          open={openMenu}
                          onClose={handleClose}
                        >
                            {single_event?.author?.includes(userDetails?.id) &&
                                <div>
                                <MenuItem onClick={handleClose}>
                                    <Button variant="contained" size="small" onClick={() => navigate("/event/edit/"+single_event?._id)}>Edit</Button>
                                </MenuItem>
                                </div>
                            }
                            
                            {!single_event?.author?.includes(userDetails?.id) && !single_event?.RSVP?.includes(userDetails?.id) &&
                                <MenuItem onClick={handleClose}>
                                    <RSVPEventButton event_details={single_event} />
                                </MenuItem>
                            }
                        </Menu>
                    </div>
                    }
                />   
                <img
                    style={{ height: 'auto', width:"100%", maxHeight: 400 }}
                    alt={"community"}
                    title={single_event?.title}
                    src={single_event?.header_img}
                />
                <CardContent>
                    <div dangerouslySetInnerHTML={{__html: event?.content}} />
                    <iframe
                        width="100%"
                        height="350"
                        style={{border:0}}
                        loading="lazy"
                        title="Google Maps"
                        allowfullscreen
                        referrerpolicy="no-referrer-when-downgrade"
                        src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyBUjLE4mdiLnDtS0TDOPgLJYe27yeEjoRc&q="+single_event?.location?.formatted_address}>
                    </iframe>
                    <p style={{fontWeight:'bold'}}>{single_event?.location?.formatted_address}</p>
                    <CategoryDisplay label={single_event?.category} />
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={2} sm={4} md={4}>
                        <p style={{fontWeight:'bold'}}>Start</p>
                            {new Date(single_event?.start_date_time).toLocaleTimeString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})}
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                        <p style={{fontWeight:'bold'}}>End</p>
                            {new Date(single_event?.end_date_time).toLocaleTimeString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})}
                        </Grid>
                    </Grid>

                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={2} sm={4} md={4}>
                            <p style={{fontWeight:'bold'}}>Host</p>
                                <AvatarGroup style={{justifyContent:'flex-end'}}>
                                {leaders.map((leader, i) =>
                                    <Avatar onClick={() => navigate("/profile/"+leader.id)} key={i} alt="Leader" src={leader.avatar} />
                                )}
                                </AvatarGroup>
                        </Grid>
                        {single_event?.RSVP?.length > 0 &&
                            <Grid item xs={2} sm={4} md={4}>
                                <p style={{fontWeight:'bold'}}>RSVPs</p>
                                <MembersPopUpList leaders={leaders} community_id={single_event?._id} title={"RSVP List"} members={members} open={membersOpen} setOpen={setMembersOpen} />
                                <AvatarGroup onClick={() => setMembersOpen(true)} total={single_event?.RSVP?.length} style={{justifyContent:'flex-end'}}>
                                    {members?.map((member, i) =>
                                        <Avatar key={i} alt="Member" src={member.avatar} />
                                    )}
                                </AvatarGroup>
                            </Grid>
                        }
                    </Grid>
                    
                    <p style={{fontWeight:'bold'}}>Gallery</p>
                    <MediaDisplayGallery mediaList={event?.media_attachments ?? []} />

                    <div style={{marginTop:30}}>
                        {!single_event?.RSVP?.includes(userDetails?.id) && !single_event?.author?.includes(userDetails?.id) ?
                            <RSVPEventButton event_details={single_event} />
                            :
                            <SlimCommentDrawer count={event?.replies_count} token={token} id={event?.id} openCommentReply={openCommentReplyDrawer} setOpenCommentReply={setOpenCommentReplyDrawer} />
                        }
                    </div>
                </CardContent>
            </Card>
        </MainLoggedInLayout>
    )
}