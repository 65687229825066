let api = process.env.REACT_APP_MKGF_MASTODON_API+'/hey_match'

export const getMatches = async ({token, id}) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch(api+"/"+id , { headers });
    const data = await response.json();
    return data
}

export const createMatch = async ({token, id, viewed}) => {
    let acct_id = id
    let body = {acct_id, viewed}
    const headers = { 'Authorization': 'Bearer '+token, 'Content-Type': 'application/json' }
    const response = await fetch(api+"/" , { method:"POST", headers, body:JSON.stringify(body) });
    const data = await response.json();
    return data
}

export const editMatch = async ({token, id, viewed}) => {
    const headers = { 'Authorization': 'Bearer '+token, 'Content-Type': 'application/json' }
    const response = await fetch(api+"/"+id , { method:"PUT", headers, body:JSON.stringify({viewed}) });
    const data = await response.json();
    return data
}

export const deleteMatch = async ({token, id}) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch(api+"/"+id , { method:"DELETE", headers });
    const data = await response.json();
    return data
}