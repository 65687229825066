import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from "react-google-autocomplete";
import { Button } from '@mui/material';

export default function LocationFilter({miles, setMiles, setLat, setLng}) {
    let YOUR_GOOGLE_MAPS_API_KEY = process.env.REACT_APP_YOUR_GOOGLE_MAPS_API_KEY

    const handleChange = (event) => {
        setMiles(event.target.value);
    };

  return (
    <>
    <Box style={{display:'flex', jusifyContent:'center', margin:10}}>                        
        <Autocomplete
            className="form-control"
            placeholder="Filter by address"
            id='google_location'
            apiKey={YOUR_GOOGLE_MAPS_API_KEY}
            onPlaceSelected={(place) => {
                setLng(place.geometry.location.lng)
                setLat(place.geometry.location.lat)
            }}
        />
      <FormControl style={{minWidth:150, marginLeft:10}}>
        <InputLabel id="demo-simple-select-label">Miles</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={miles}
          label="Miles"
          onChange={handleChange}
        >
          <MenuItem value={10}>10 mi</MenuItem>
          <MenuItem value={20}>20 mi</MenuItem>
          <MenuItem value={30}>30 mi</MenuItem>
        </Select>
      </FormControl>
    </Box>

    {document.getElementById('google_location')?.value && <Button style={{marginLeft:20}} onClick={() => {
      setMiles(10)
      setLat('')
      setLng('')
      document.getElementById('google_location').value = ''
      }}>Reset</Button>}
      </>
  );
}