export const getConversations = async (token) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch('https://makegayfriends.com/api/v1/conversations', { headers });
    const data = await response.json();
    return data
}

export const deleteConversation = async ({token, id}) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch('https://makegayfriends.com/api/v1/conversations/'+id, { method:"DELETE", headers });
    const data = await response.json();
    return data
}