import { Card, CardContent, Grid } from "@mui/material";
import { useState } from "react";

export const MediaDisplayGallery = ({mediaList}) => {
    const [focusMedia, setFocusMedia] = useState('')
    let placeholder_img = mediaList[0]
    let preview_style = {width:80, display:'flex', alignItems:'center'}
    return (
        <>
        {mediaList.length > 0 ?
        <>
        <Card>
            <CardContent>
                {focusMedia?.includes("mp4") ?
                    <video controls style={{width:'100%', margin:5}}>
                        <source src={focusMedia} type="video/mp4" />
                    </video>
                    : focusMedia ?
                    <img style={{width:'100%', margin:5}} alt="Community Pic" src={focusMedia} />
                    :
                    ""
                }
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {mediaList.map((media, i) =>
                        media.url.includes("mp4") ?
                        <Grid key={i} item xs={2} sm={4} md={4} onClick={() => setFocusMedia(media.url)} style={{alignItems:'center', display:'flex'}}>
                            <video controls={false} onPause={true} style={preview_style}>
                                <source src={media.url} type="video/mp4" />
                            </video>
                        </Grid>
                        :
                        <Grid key={i} item xs={2} sm={4} md={4} onClick={() => setFocusMedia(media.url)} style={{alignItems:'center', display:'flex'}}>
                        <img
                            src={media.url}
                            alt={'Community Gallery'}
                            loading="lazy"
                            onLoad={e => {
                                if(e.target.height < 10) e.target.src = placeholder_img
                            }}
                            style={preview_style}
                            onError={(e) => {
                                e.target.style.textAlign = 'center';
                                e.target.style.background = 'url('+placeholder_img+')';
                                e.target.style.backgroundSize = 'contain';
                                e.target.style.backgroundRepeat = 'no-repeat';
                                if(e.currentTarget.src !== placeholder_img) 
                                    e.target.src = placeholder_img
                                }}
                        />
                        </Grid>
                    )}

                </Grid>
            </CardContent>
        </Card>
        </>
        :
        ""
        }
        </>
    )
}