import { useParams } from "react-router";
import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout"
import { SingleConversation } from "../../components/messages/SingleConversation";

export function MessageShow() {
  const { id } = useParams()
  return (
    <MainLoggedInLayout>
        <SingleConversation init_id={id} />
    </MainLoggedInLayout>
  );
}