import { QueryClient, useMutation, useQuery } from "@tanstack/react-query"
import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout"
import { useContext, useEffect, useMemo, useState } from "react"
import { UserContext } from "../../context/UserContext"
import { Button, Card, CardActions, CardContent, CardHeader, List, ListItem, TextField } from "@mui/material"
import { PostMedia } from "../../components/post/card/PostMedia"
import { useNavigate, useParams } from "react-router"
import { editForum, getForum } from "../../controllers/forum/forum"
import { CategoryPicker } from "../../components/category.picker"
import { editComment } from "../../controllers/posts/comments"
import { getSinglePost } from "../../controllers/posts/posts"

export const EditForum = () => {
    const userDetails = useContext(UserContext)
    const [media_ids, setMediaIds] = useState([])
    const [imageUrls, setImageUrls] = useState([])
    const [category, setCategory] = useState('')
    const [status, setStatus] = useState('')
    let navigate = useNavigate()
    const { id } = useParams()
    let token = userDetails?.token
    const { data:forum, refetch } = useQuery({ queryKey: ['getSingleForum'], queryFn: () => getForum(token, id) })
 
    let single_forum = useMemo(() => forum?.forum ?? {} , [forum])

    const { data:content, refetch:refetchPost } = useQuery({ queryKey: ['getSinglePost'], queryFn: () => getSinglePost(single_forum?.status, token) })
    
    useEffect(() => {
        refetch()
        refetchPost()
    })
    
    useEffect(() => {
        let img_urls = []
        content?.media_attachments?.filter(item => img_urls.push(item.url))
        setImageUrls(img_urls)
    }, [content])
    
    useEffect(() => {
      setStatus(single_forum?.title)
      single_forum?.category && setCategory(single_forum?.category)
    }, [single_forum])

    const queryClient = new QueryClient()

    const mutationComment = useMutation({
      mutationFn: editComment,
      onSuccess: (data) => {
        // Invalidate and refetch
        console.log(data)
        queryClient.invalidateQueries({ queryKey: ['editComment'] })
        navigate("/forum/show/"+single_forum?._id)
      },
    })
  
    const send_edit = () => {
        mutationComment.mutate({
        id:single_forum?.status,
        body:{
            status,
            media_ids
        },
        token
      })
    }
    const mutation = useMutation({
        mutationFn: editForum,
        onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ['editForum'] })
        send_edit()
        },
    })
    
    const sendEditedForum = () => { 
        mutation.mutate({
            id:single_forum?._id,
            body:{
                title:status,
                category
            },
            token:userDetails?.token
        })
    }

    return (
        <MainLoggedInLayout>
            {single_forum?.author?.includes(userDetails?.id) ?
            <Card>
                <CardHeader
                    title="Edit topic!"
                    subheader={<Button variant="contained" color="error" onClick={() => navigate("/forum/delete/"+single_forum?._id)}>Delete</Button>}
                />
                <CardContent>
                    
                    <List>
                        <div style={{marginLeft:20, marginRight:20}}>
                            <TextField value={status} onChange={(e) => setStatus(e.target.value)} fullWidth multiline id="outlined-basic" label='Edit Topic' variant="outlined" />
                            <PostMedia imageUrls={imageUrls} setImageUrls={setImageUrls} media_ids={media_ids} setMediaIds={setMediaIds} token={token} />
                        </div>

                        <ListItem>
                            <CategoryPicker category={category} setCategory={setCategory} />
                        </ListItem>
                    </List>
                    
                </CardContent>
                <CardActions>
                    <Button fullWidth variant="contained" onClick={sendEditedForum}>Update Topic!</Button>
                </CardActions>
      
            </Card>
            :
            "You can not access this page"
            }
            
        </MainLoggedInLayout>
    )
}