import { Alert, Button, Grid } from "@mui/material"
import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout"
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { useQuery } from '@tanstack/react-query'
import { getAccounts } from "../../controllers/accounts/accounts";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { UserContext } from "../../context/UserContext";
import { Loading } from "../../util/util"

export const AccountList = () => {
    const [offset, setOffset] = useState(0)
    const [local, setLocal] = useState(true)
    const [errImg, setErrImg] = useState([])
    const userDetails = useContext(UserContext)
    let token = userDetails?.token ?? ''
    let navigate = useNavigate()
    const myRef = useRef(null)
    const { data:accounts, refetch } = useQuery({ queryKey: ['getAllAccounts'], queryFn: () => getAccounts(offset, local, token) })
    useEffect(() => {
        refetch()
        myRef.current.scrollIntoView()
    })

    if(accounts?.length < 1){
        return (
            <MainLoggedInLayout>
                <Loading />
            </MainLoggedInLayout>
        )
    }
    
    return (
        <MainLoggedInLayout>
            <Card ref={myRef}>
                <CardContent>
                    <Alert severity="success">
                        {local ?
                        <Typography>View MakeGayFriends members only</Typography>
                        :
                        <Typography>All members from all servers on Mastodon following MakeGayFriends</Typography>
                        }
                    <Button 
                        variant="contained" 
                        onClick={() => {
                            setLocal(local ? false : true)
                            setOffset(0)
                        }}
                    >
                        Switch
                    </Button>
                    
                    </Alert>
                </CardContent>
            </Card>
            <Grid container spacing={{ xs: 1, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{maxWidth:'100%', margin:'auto', justifyContent:'center'}}>
                
                {accounts?.length < 1 && "No reults, restting..."}
                
                {accounts?.length > 0 && accounts?.map((account, index) => 
                !account.avatar.includes("missing") && !errImg.includes(account.id) &&
                <Grid item xs={2} sm={3} md={3} key={index} style={{padding:4}}>
                    <Card sx={{ maxWidth: 345 }} onClick={() => navigate("/profile/"+account.id)}>
                        <CardActionArea>
                            <CardMedia
                            component="img"
                            height="140"
                            image={account.avatar}
                            alt={account.username}
                            onError={() => setErrImg(errImg => [...errImg, account.id])}
                            />
                            <CardContent>
                            <Typography style={{wordWrap:"anywhere"}} gutterBottom variant="p" component="div">
                                @{account.acct}
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                )}
            </Grid>
            
            <Card>
                <CardContent style={{textAlign:'center'}}>
                    {accounts && accounts.length > 39 && 
                        <Button 
                            onClick={() => {
                                setOffset(offset+40)
                            }}
                            variant="contained"
                            style={{margin:5}}
                        >
                            Next
                        </Button>}
                    {accounts && offset > 0 && 
                        <Button 
                            onClick={() => {
                                setOffset(offset-40)
                            }}
                            variant="contained"
                            style={{margin:5}}
                        >
                            Previous
                        </Button>}
                </CardContent>
            </Card>
        </MainLoggedInLayout>
    )
}