export const getAccounts = async (offset, local, token) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch('https://makegayfriends.com/api/v1/directory?offset='+offset+"&local="+local, { headers });
    const data = await response.json();
    return data
}

export const getSingleAccount = async (id, token, max_id) => {
    const account_headers = { 'Authorization': 'Bearer '+token }
    const account_response = await fetch('https://makegayfriends.com/api/v1/accounts/'+id, { account_headers });
    const account_data = await account_response.json();
    
    const headers = { 'Authorization': 'Bearer '+token }
    const fetch_url = max_id ? 'https://makegayfriends.com/api/v1/accounts/'+id+'/statuses?exclude_replies=true&limit=40&max_id='+max_id : 'https://makegayfriends.com/api/v1/accounts/'+id+'/statuses?exclude_replies=true&limit=40'
    const response = await fetch(fetch_url, { headers });
    const status_data = await response.json();
    return {account:account_data, statuses:status_data}
}

export const getAccount = async (id, token) => {
    const account_headers = { 'Authorization': 'Bearer '+token }
    const account_response = await fetch('https://makegayfriends.com/api/v1/accounts/'+id, { account_headers });
    const data = await account_response.json();
    return data
}

export const updateSingleAccount = async ({body, token}) => {
    const { ...allValues } = body
    let formData = new FormData()
    Object.keys(allValues).forEach(key => { 
        formData.append([key], allValues[key]);
    })
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch('https://makegayfriends.com/api/v1/accounts/update_credentials', { method:"PATCH", headers, body:formData });
    const data = await response.json();
    return data
}

export const updateAvatar = async ({image, token}) => {
    let formData = new FormData()
    formData.append('avatar', image);
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch('https://makegayfriends.com/api/v1/accounts/update_credentials', { method:"PATCH", headers, body:formData });
    const data = await response.json();
    return data
}

export const followManager = async ({token, follow, id}) => {
    const headers = { 'Authorization': 'Bearer '+token }
    let fetch_url = follow ? 'https://makegayfriends.com/api/v1/accounts/'+id+'/follow' : 'https://makegayfriends.com/api/v1/accounts/'+id+'/unfollow'
    const response = await fetch(fetch_url, { method:"POST", headers });
    const data = await response.json();
    return data
}

export const getFollowingList = async (id, token) => {
    const headers = { 'Authorization': 'Bearer '+token }
    const response = await fetch('https://makegayfriends.com/api/v1/accounts/'+id+'/following', { headers });
    const data = await response.json();
    return data
}