import { useContext, useState } from "react"
import { MainLoggedInLayout } from "../../components/layouts/main.loggedIn.layout"
import { CreatePost } from "../../components/post/CreatePost"
import { UserContext } from "../../context/UserContext"
import { Alert, Card, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { CalendarMonth, ChevronRight, Forum, Group, InsertEmoticon, PostAdd } from "@mui/icons-material"
import { useNavigate } from "react-router"

export const PostCreate = () => {
    const userDetails = useContext(UserContext)
    const [open, setOpen] = useState(true)
    const [status, setStatus] = useState("")
    let navigation = useNavigate()
    let key = [
        {label:'Create A Community', url:"/community/create", icon:<Group />},
        {label:'Upload An Event', url:"/event/create/null", icon:<CalendarMonth />},
        {label:'Create A Topic', url:"/forum/create", icon:<Forum />}
    ]
    return (
        <MainLoggedInLayout>
            <div style={{height:'70vh', display:'flex', alignItems:'center', justifyContent:'center'}}>
                <div style={{textAlign:'center'}}>
                    <Alert icon={<InsertEmoticon fontSize="inherit" />} severity="info">
                        Thanks for participating in our community.
                    </Alert>

                    <Card style={{padding:10, margin:10}}>
                    <ListItem
                        style={{margin:10}}
                        secondaryAction={
                        <IconButton edge="end" aria-label="comments">
                            <ChevronRight />
                        </IconButton>
                        }
                        disablePadding
                    >
                        <ListItemIcon>
                            <PostAdd />
                        </ListItemIcon>
                        <ListItemButton role={undefined} onClick={() => setOpen(true)} dense>
                        <ListItemText id={'create-menu'} primary={'Upload A Public Post'} />
                        </ListItemButton>
                    </ListItem>
                    {key.map((item, i) =>
                    <ListItem
                        key={i}
                        style={{margin:10}}
                        secondaryAction={
                        <IconButton edge="end" aria-label="comments">
                            <ChevronRight />
                        </IconButton>
                        }
                        disablePadding
                    >
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemButton role={undefined} onClick={() => navigation(item.url)} dense>
                        <ListItemText id={'create-menu'} primary={item.label} />
                        </ListItemButton>
                    </ListItem>
                    )}
                    </Card>
                </div>
            </div>
            <CreatePost token={userDetails?.token} open={open} setOpen={setOpen} status={status} setStatus={setStatus} buttonTitle={"Upload Status"} visibility={'public'} />
        </MainLoggedInLayout>
    )
}